import {ReactComponent as ArrowIcon} from "assets/common/arrow.svg";
import {useNavigate} from "react-router";
import '../../../components/TabsHeader/style.scss'
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {Input} from "../../../components/lego/Input/Input";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";
import { isEmptyInput} from "../../../utils/login.utils";
import {FormErrorMessages} from "../../../constants/logInErrorMessages.constants";
import {changePassword} from "../../../utils/firebase/auth/createUser.utils";
import { SUCCESS_ROUTE} from "../../../constants/routes.constants";
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
const PASSWORD_ID='password'
const CONFIRMED_PASSWORD_ID='confirmedPassword'
const EditPassword = () => {
    const navigate = useNavigate()
    const { t } = useTranslation('onboarding');
    const app = useTranslation('app');
    const [password,setPassword]=useState('')
    const [confirmedPassword,setConfirmedPassword]=useState('')
    const {errorState, setErrorState, clearErrorMessage}=useLogInErrorLogic()
    const onSend=()=>{


        if(!isEmptyInput(password)) {
            setErrorState({message:t(FormErrorMessages.EMPTY_PASSWORD),errorInputId:PASSWORD_ID})
            return;
        }

        if(!isEmptyInput(confirmedPassword)) {
            setErrorState({message:t(FormErrorMessages.EMPTY_CONFIRMATION_PASSWORD),errorInputId:CONFIRMED_PASSWORD_ID})
            return;
        }

        if(password.length<=6) {
            setErrorState({message:t(FormErrorMessages.SHORT_PASSWORD),errorInputId:PASSWORD_ID})
            return;
        }

        if(password !== confirmedPassword) {
            setErrorState({message:t(FormErrorMessages.PASSWORDS_DO_NOT_MATCH),errorInputId:CONFIRMED_PASSWORD_ID})
            return;
        }


        changePassword(password,  () => navigate(SUCCESS_ROUTE))
    }

    const clearErrorData=(value:string, setInputValue: (inputValue:string)=>void)=>{
        clearErrorMessage()
        setInputValue(value)
    }
    return (
        <div  className="change-password-wrapper">
            <div className="tabs-header-container row-container space-between align-center">
                <ArrowIcon className="tabs-header-container__back" onClick={() => navigate(-1)} />
                <div className="tabs-header-container__title typography-24 typography-bold font-bebas">
                    {app.t("change_password")}
                </div>
                <div/>
            </div>
            <ContentContainer>
                <div className={'registrationForm'}>

                    <Input onChange={(value)=>clearErrorData(value,setPassword)}
                           id={PASSWORD_ID}
                           inputType={'password'}
                           placeholderText={t("createAccountScreen.passwordPlaceholder")}
                           errorMessage={errorState.errorInputId===PASSWORD_ID?errorState.message:''}
                    />

                    <Input onChange={(value)=>clearErrorData(value,setConfirmedPassword)}
                           id={CONFIRMED_PASSWORD_ID}
                           inputType={'password'}
                           placeholderText={t("createAccountScreen.confirmPasswordPlaceholder")}
                           errorMessage={errorState.errorInputId===CONFIRMED_PASSWORD_ID?errorState.message:''}
                    />
                </div>
            </ContentContainer>
                <Button className="btn-change-password" onClick={onSend} text={app.t("save_new_password")}
                        buttonTheme={ButtonThemes.DARK} width={100}
                />
        </div>
    )
}

export default EditPassword