

export const misconceptionRules = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    }
]

export const images = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.6.1.png?alt=media&token=ed335dd8-3988-4e06-a8c0-094535b8bc19",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.6.2.png?alt=media&token=24d4f9ec-a49a-4022-a582-440d51a7d8cb",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.6.3.png?alt=media&token=799f424a-27b8-40b7-92b3-b43e031bb685",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.6.4.png?alt=media&token=16d41532-a763-4419-91d0-a9d86f7222f7",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.6.5.png?alt=media&token=f48bedf5-2081-4153-bd60-435bf4507361",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.6.6.png?alt=media&token=5a73ff61-79c8-4909-a00a-e97cf6b10391",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.6.7.png?alt=media&token=51cc5ac9-1f3e-4020-9191-e3b0d1133c19"
]
