import {useTranslation} from "react-i18next";
import {src1} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("anal_sex_title")
    const subtitle = t("anal_sex_subtitle")
    const subtitle1 = t("anal_sex_subtitle1")
    const list = t("anal_sex_list", { returnObjects: true })
    const list1 = t("anal_sex_list1", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[0]} alt="course-preview" />
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[1]} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro