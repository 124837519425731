import './Carousel.scss';
import React from "react";
import 'react-alice-carousel/lib/alice-carousel.css';
import {ProgressDivision} from "../ProgressBar/ProgressDivision/ProgressDivision";
import {ProgressBarState} from "../../constants/onBoardingConstants/progressBar.constants";
import AliceCarousel, {DotsItem} from "react-alice-carousel";
import {ReactComponent as ArrowIcon} from "assets/common/arrow.svg";

const CustomDot=({active,onClick}:{onClick?:()=>void, active?:boolean})=>{
    return <ProgressDivision onClick={onClick} divisionStatus={
        active ? ProgressBarState.FILLED : ProgressBarState.UNFILLED
    }/>
}


export const CarouselComponent =({itemPadding,children}:{itemPadding:number,children:React.ReactElement[]})=>{
    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
    };
    return (
        <div className={'carouselWrapper'}>
            <AliceCarousel
                renderDotsItem={(e: DotsItem)=><CustomDot active={e.isActive}/>}
                renderPrevButton={()=><ArrowIcon className="carousel-left-arrow"/>}
                renderNextButton={()=><ArrowIcon className="carousel-right-arrow"/>}
                mouseTracking={true}
                infinite={true}
                items={children}
                paddingLeft={itemPadding}
                paddingRight={itemPadding}
                responsive={responsive}
            />
        </div>
    )
}