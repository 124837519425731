import {useTranslation} from "react-i18next";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("flirting_with_impact_title")
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__list">
                {t("flirting_with_impact_list", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
        </div>
    )
}
export default Intro