import {useTranslation} from "react-i18next";
import {src1} from "./constants";

const Microphone = () => {
    const { t } = useTranslation('app');
    const subtitle = t("sex_toys_subtitle6")
    const article = t("sex_toys_article6")
    const note = t("sex_toys_note6")
   return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[5]} alt="course-preview" />
            </div>
        </div>
    )
}

export default Microphone