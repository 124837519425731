export const MasturbationRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    }
]

export const images1 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.1.png?alt=media&token=85172e12-d297-4a24-b0a2-61619da50586"
]

export const images2 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.2.png?alt=media&token=bb580b99-7fb3-49ec-a66a-b85c356b4328",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.3.png?alt=media&token=fb2adde5-3f92-4a7c-b646-b2d07fc5dbd8",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.4.png?alt=media&token=e3ff91ae-872c-4e82-bf9f-b43ed8743b30",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.5.png?alt=media&token=3a5fe830-7ddc-458a-93db-f9bd7c1a7fff",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.6.png?alt=media&token=4b44cb03-a8a3-4e50-8cae-59c9745e228a",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.7.png?alt=media&token=1f59f497-11b6-41e8-b6a9-5083742a211e",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.8.png?alt=media&token=92a3a802-9271-47fc-8237-6136581fc020",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.9.png?alt=media&token=3efda0cb-b64d-45d3-a6b8-b5f2b09d848d",
          ]

export const images3 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.10.png?alt=media&token=ee15422a-3777-41a8-b0f1-f8b8fdd90b09",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.11.png?alt=media&token=7fa6c0ff-c8b7-451a-9b87-563fe1832910",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.12.png?alt=media&token=f41d659a-588d-4f10-8027-939172ba9bbd",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.13.png?alt=media&token=0ea6afbf-d80f-4c2d-afba-9d4549582aa4",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.14.png?alt=media&token=1bc094f3-7dc7-4f49-8903-dcaf848fb0b2",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3.15.png?alt=media&token=92d4469b-3ab5-490c-a305-50f56bdca740"

]
