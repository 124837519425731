import './ReadinessContainer.scss';
import {learnHowEasyLogo} from "../../../assets/onBoardingImages";
import {OnBoardingImage} from "../OnBoardingImage/OnBoardingImage";
import {TFunction} from "i18next";

const READINESS_VALUE='80.80%'
export const ReadinessContainer =({t}: { t: TFunction })=>{
    const pointsEasyToLearn=[
        t("payInfoScreen.howEasyToLearn.unveilSexualityText"),
        t("payInfoScreen.howEasyToLearn.idealLoverText"),
        t("payInfoScreen.howEasyToLearn.phrasesDuringSexText")
    ]

    return (
            <div className={'readinessContainer'}>
                <div>
                    <h4 className={'readinessTitle font-bebas'}>{t("payInfoScreen.readinessText")}:</h4>
                    <h4 className={'readinessValue font-bebas'}>{READINESS_VALUE}</h4>
                    <p className={'newJourneyText'}>{t("payInfoScreen.journeyToLearnText")}</p>
                </div>
                <div className={'howEasyContainer'}>
                    <div className={'howEasyLogo'}>
                        <OnBoardingImage imageSrc={learnHowEasyLogo} />
                        <h4 className={'howEasyLogoTitle font-bebas'}>{t("payInfoScreen.howEasyToLearn.title")}</h4>
                    </div>
                    <ul>
                        {pointsEasyToLearn.map((pointText)=>{
                            return  <li key={pointText}>{pointText}</li>
                        })}
                    </ul>
                </div>
            </div>
    )
}
