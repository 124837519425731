import {useTranslation} from "react-i18next";
import {src1} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("sex_scenarios_course_title")
    const subtitle = t("sex_scenarios_course_subtitle")
    const article = t("sex_scenarios_course_subtitle1")
    const article1 = t("sex_scenarios_course_article")
    const list = t("sex_scenarios_course_list", { returnObjects: true })
    const list1 = t("sex_scenarios_course_list1", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article1}
            </div>
        </div>
    )
}

export default Intro