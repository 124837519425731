import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './PayInfoScreen.scss';
import {Button} from "../../../components/lego/Button/Button";
import {Timer} from "../../../components/Timer/Timer";
import {IntimateLifeStates} from "../../../components/onboarding/IntimateLifeStates/IntimateLifeStates";
import {
    ReadinessContainer
} from "../../../components/onboarding/ReadinessContainer/ReadinessContainer";
import {
    PointsWillLearnContainer
} from "../../../components/onboarding/PointsWillLearnContainer/PointsWillLearnContainer";
import {MoneyBackContainer} from "../../../components/onboarding/MoneyBackContainer/MoneyBackContainer";
import {SubscriptionBenefits} from "../../../components/onboarding/SubscriptionBenefits/SubscriptionBenefits";
import {AppPreview} from "../../../components/onboarding/AppPreview/AppPreview";
import {Reviews} from "../../../components/onboarding/Reviews/Reviews";
import {useNavigate} from "react-router";
import {PaymentModal} from "../../../components/onboarding/PayInfoContainer/PaymentModal";
import {useEffect, useRef, useState} from "react";
import SpecialOrder from "./SpecialOrder";
import '../../../components/onboarding/PayInfoContainer/PayInfoContainer.scss';
import GiftModal from "./GiftModal";
import NewDiscountModal from "./NewDiscountModal";
import {priceValues} from "../../../constants/price";

const TEN_MINUTES_TIMER_DURATION=10*60*1000;
export const PayInfoScreen =({nextRoute}: { nextRoute:string })=>{
    const { t } = useTranslation('onboarding');
    const navigate=useNavigate()
    const specialOrderRef = useRef(null)
    const [selected, setSelected] = useState(2)
    const executeScroll = () => specialOrderRef?.current?.scrollIntoView()


    const [open, setIsOpen] = useState(false)
    const [isGiftOpen, setIsGiftOpen] = useState(false)
    const [isNewPrice, setIsNewPrice] = useState(false)
    const [isNewDiscount, setIsNewDiscount] = useState(false)
    const [isClosedFirsTime, setIsClosedFirsTime] = useState(false)
    const [price, setPrice] = useState(89.99)

    useEffect(() => {
        if(isClosedFirsTime && !isGiftOpen){
            setIsGiftOpen(true)
        }
    }, [isClosedFirsTime])


    const [isTimerOver, setIsTimerOver] = useState(false)


    useEffect(() => {
        setTimeout(() => {
            setIsTimerOver(true)
        }, TEN_MINUTES_TIMER_DURATION)
    },[])

    const getWithDiscount = () => {
        setIsNewDiscount(false)
        setIsNewPrice(true)
        setIsOpen(true)
    }

    useEffect(() => {
        localStorage.setItem("price_plan", `${priceValues.indexOf(price)}`)
        localStorage.setItem("isTimerOver", `${isTimerOver}`)
    }, [price, isTimerOver]);

    const closeModal = () => setIsOpen(false)

    const onCLick= () => setIsOpen(true)

    return (
        <div className={'payInfoPage'}>
        <div className={'timer timer-sticky'}>
            <div className={'timerContainer'}>
                <p className={'timerText font-bebas'}>{t("payInfoScreen.discountTimerText")}</p>
               <Timer duration={TEN_MINUTES_TIMER_DURATION} className={'headerTimerDesign font-bebas'}/>
            </div>
                <Button onClick={executeScroll} className={'pulseButton'} text={t("payInfoScreen.tryNowButtonText")} buttonTheme={ButtonThemes.DARK} width={50} isDisabled={false} />
        </div>
            {/*<OnBoardingImage imageSrc={null}/>*/}
            <IntimateLifeStates t={t}/>
            <p className={'guaranteeText font-bebas'}>{t("payInfoScreen.guaranteeText")}</p>
            <ReadinessContainer t={t}/>
            <PointsWillLearnContainer t={t}/>
            <div ref={specialOrderRef}/>
            <div className="mt-24" />
            <SpecialOrder
                t={t}
                timerDuration={TEN_MINUTES_TIMER_DURATION}
                isTimerOver={isTimerOver}
                onClick={() => setIsOpen(true)}
                price={price}
                selected={selected}
                setSelected={setSelected}
                setPrice={setPrice} />
            {/*<PayInfoContainer onCLick={onCLick} t={t} timerDuration={TEN_MINUTES_TIMER_DURATION}/>*/}
            <MoneyBackContainer t={t} />
            <AppPreview t={t} />
            <SubscriptionBenefits t={t}/>
            <Reviews t={t} />
            <SpecialOrder
                t={t}
                timerDuration={TEN_MINUTES_TIMER_DURATION}
                isTimerOver={isTimerOver}
                price={price}
                selected={selected}
                setSelected={setSelected}
                onClick={() => setIsOpen(true)}
                setPrice={setPrice} />
            <PaymentModal
                setIsClosedFirsTime={setIsClosedFirsTime}
                isClosedFirsTime={isClosedFirsTime}
                t={t}
                open={open}
                setOpen={setIsOpen}
                price={price}
                isNewPrice={isNewPrice}
                setIsNewPrice={setIsNewPrice}
                isTimerOver={isTimerOver} />
            <GiftModal
                t={t}
                closeModal={closeModal}
                setOpen={setIsGiftOpen}
                onSubmit={() => {
                    setIsOpen(false)
                    setIsNewDiscount(true)
                }}
                isOpen={isGiftOpen} />
            <NewDiscountModal
                t={t}
                timerDuration={TEN_MINUTES_TIMER_DURATION}
                isTimerOver={isTimerOver}
                onClick={() => setIsOpen(true)}
                setPrice={setPrice}
                isOpen={isNewDiscount}
                setOpen={setIsNewDiscount}
                onSubmit={getWithDiscount}
            />
        </div>
    )
}
