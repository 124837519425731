import {useTranslation} from "react-i18next";
import {images} from "./constants";

const Part2 = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("escaping_the_friend_zone_subtitle5")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[4]} alt="course-preview"/>
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("escaping_the_friend_zone_article5")}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("escaping_the_friend_zone_note5")}
                </div>
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("escaping_the_friend_zone_subtitle6")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[5]} alt="course-preview"/>
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("escaping_the_friend_zone_article6")}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("escaping_the_friend_zone_note6")}
                </div>
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("escaping_the_friend_zone_subtitle7")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[6]} alt="course-preview"/>
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("escaping_the_friend_zone_article7")}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("escaping_the_friend_zone_note7")}
                </div>
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("escaping_the_friend_zone_note8")}
            </div>
        </div>
    )
}

export default Part2