import {useTranslation} from "react-i18next";
import {images4} from "./constants";

export const MaleGaze = () => {
    const { t } = useTranslation('app');
    const title1 = t("techniques_title50")
    const subtitle55 = t("techniques_title51")
    const list = t("techniques_list4", { returnObjects: true })
    const list1 = t("techniques_list5", { returnObjects: true })


    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images4[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle55}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title52")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images4[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("techniques_list6", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title53")}
            </div>
            <div className="child-container__list">
                {t("techniques_list7", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title54")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images4[2]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("techniques_list8", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title55")}
            </div>
            <div className="child-container__list">
                {t("techniques_list9", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title56")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images4[3]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("techniques_list10", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title57")}
            </div>
            <div className="child-container__list">
                {t("techniques_list11", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title58")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images4[4]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("techniques_list12", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title59")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article53")}
            </div>
        </div>
    )
}