import {useTranslation} from "react-i18next";
import {src1} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("enhancing_intimacy_title")
    const subtitle = t("enhancing_intimacy_subtitle")
    const note = t("sex_toys_note")
    const list = t("enhancing_intimacy_list", { returnObjects: true })
    const list1 = t("enhancing_intimacy_list1", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
        </div>
    )
}

export default Intro