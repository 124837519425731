import {useTranslation} from "react-i18next";
import {images9} from "../FirstDateRule/constants";

const Tasks = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle1")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article1")}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle2")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article2")}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle3")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article3")}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle4")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article4")}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle5")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={images9[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article5")}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle6")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article6")}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle7")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article7")}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle8")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article8")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle9")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images9[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article9")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle10")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article10")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle11")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article11")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle12")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article12")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle13")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images9[2]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article13")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle14")}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("flirting_with_impact_article14")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("flirting_with_impact_subtitle15")}
            </div>
            <div className="child-container__list">
                {t("flirting_with_impact_list15", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>

        </div>
    )
}
export default Tasks