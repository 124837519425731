export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
]

export const taboo1Src= [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.11.1.png?alt=media&token=f5648cba-1dcb-427a-8bcf-c02934b211cd",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.11.2.png?alt=media&token=cb4483bd-7324-4e3f-aaf2-23607c7e413f",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.11.3.png?alt=media&token=a8bec2d7-fb4d-4ce7-b202-1a1eaaacd945",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.11.4.png?alt=media&token=deb458fc-790a-43da-b332-8597aba0e643"
]


