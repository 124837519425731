import {useTranslation} from "react-i18next";
import  {images} from "./constants";

export const Part2 = () => {
    const { t } = useTranslation('app');

   return(
        <div className="child-container">
            <div className="child-container__article-title typography-16">
                {t("techniques_article11")}
            </div>

            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title9")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article12")}
            </div>

            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title10")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article13")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title11")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article14")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title12")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article15")}
            </div>


            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title13")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article16")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title14")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article17")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title15")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[2]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article18")}
            </div>

        </div>
    )
}