import {useTranslation} from "react-i18next";
import {images} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("turning_her_on_without_touching_title")
    const list = t("turning_her_on_without_touching_list", { returnObjects: true })

    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__list">
                {list.map((i: string, idx: number) => <div className="child-container__list mt-16 typography-16">{idx + 1}. {i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[0]} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro