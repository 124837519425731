export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    }
]



export const images =[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.14.1.png?alt=media&token=84637f90-9af8-461a-ae41-1ed833a9d6a9",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.14.2.png?alt=media&token=ff01f5f6-da32-4708-8bb8-2636057208c5"
]

