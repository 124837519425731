import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";

export const Part2 = () => {
    const { t } = useTranslation('app');
 return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas  typography-18 typography-bold">
                {t("game1_subtitle2")}
            </div>

            <div className="child-container__article-title typography-16">
                {t("game1_article2")}
            </div>
        </div>
    )
}