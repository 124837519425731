import {useTranslation} from "react-i18next";
import {images3} from "../FirstDateRule/constants";


const Keys = () => {
    const { t } = useTranslation('app');
    const arr = new Array(8).fill("")
    return(
        <div className="child-container">

            {arr.map((i, idx) =>  {
                return <><div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                    {t(("reading_body_language_subtitle" + (idx + 1)))}
                </div>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={images3[idx]} alt="course-preview" />
                    </div>
                    <div className="child-container__list">
                        {t(("reading_body_language_list" + (idx + 1)), {returnObjects: true}).map((i: string, idx: number) => <div
                            key={idx}
                            className="child-container__list mt-16 typography-14">{i}</div>)}
                    </div>
                </>})}
        </div>
    )
}

export default Keys