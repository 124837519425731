import { createUserWithEmailAndPassword, sendEmailVerification, updatePassword } from "firebase/auth";
import {auth, db} from "../../../firebase/firebase.config";
import {writeUserDataToDatabase, getUserData} from "../userData.utils";
import {ref, set} from "firebase/database";

export const createUserAccount=(email:string, password:string,username:string,emailVerificationInfoScreen:()=>void)=> {
    createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            await sendEmailVerification(userCredential.user)
            await writeUserDataToDatabase(userCredential.user.uid, username, email)
            await auth.signOut()
                emailVerificationInfoScreen()
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
}

export const changePassword = (password:string, next: () => void) => {
    const user = auth.currentUser;
    if(!user) return
    updatePassword(user, password).then(() => {
        // Update successful.
        next()
    }).catch((error) => {
        const errorMessage = error.password;
    });
}

export const changeUsername = async(username:string, next: () => void) => {
    const user = auth.currentUser;
    if(!user) return
    const oldUserData = await getUserData(user.uid);
    if (!oldUserData) return
    const userRef = ref(db, "usersData/" + user.uid);
    await set(userRef, {
        ...oldUserData,
        username
    });
}

