export const BONDING_ADVENTURES_COUPLES = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FBonding%2F5.1%20Spin%20the%20Bottle.png?alt=media&token=b432a69e-444c-4ffa-a1b2-7b5d45b00767'
    },
    {
        img:"https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FBonding%2F5.2%20Truth%20or%20Dare.png?alt=media&token=406f05cd-799d-42f9-b730-4bcf87c386f0"
    }

] as const
export const MATURE_INTIMACY_CHALLENGE = [
    {
        img:'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1%20Intimacy%20Challenge%2018%2B.png?alt=media&token=57553db1-69e4-4d68-849a-7c649b17e76e'
    },
    {
        img:'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.2%20Oral%20Mastery.png?alt=media&token=f3d582ed-fe0c-4854-9ad8-2bf517d07669'
    },
    {
        img:'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.3%20The%20Hard%20Mode%20Experience.png?alt=media&token=2812c58b-0a7a-4ca8-ab80-327a65ced5f0'
    },
] as const
export const DECIDE_YOUR_DESTINY = [
    {
        img:'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.1%20Truth%20or%20Dare.png?alt=media&token=49abfaf3-b6a5-403b-80a0-a1a71d8f21fa'
    },
    {
        img:'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2%20Action-Packed%20Choices.png?alt=media&token=4298dd16-9cc6-4dc0-bc34-31df9b02c0c3'
    },
    {
        img:'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.3%20Sizzling%20Hot%20Tasks.png?alt=media&token=df1c3a3e-697f-40ee-b655-20a03d057882'
    },
] as const

export const BONDING_ADVENTURES_COUPLES_ROUTES = [
    '/bottle',
    '/truth-or-dare'
]
export const MATURE_INTIMACY_CHALLENGE_ROUTES = [
    '/sex',
    '/oral-sex',
    '/hard-made'
]

export const DECIDE_YOUR_DESTINY_ROUTES = [
    '/truth',
    '/action-packed',
    '/hot-tasks'
]
export const BONDING_ADVENTURES_COUPLES_NUMBER = '0'
export const MATURE_INTIMACY_CHALLENGE_NUMBER = '1'

export const DECIDE_YOUR_DESTINY_NUMBER = '2'