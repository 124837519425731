import {useTranslation} from "react-i18next";
import {
   images2
} from "./constants";

export const Flirting = () => {
    const { t } = useTranslation('app');

    return(
        <div className="child-container">
            <div className="child-container__article-title typography-16">
                {t("techniques_article29")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title26")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={images2[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article30")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title27")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images2[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article31")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title28")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images2[2]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article32")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title29")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images2[3]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article33")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title30")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images2[4]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article34")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title31")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images2[5]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article35")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title32")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images2[6]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article36")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title33")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images2[7]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article37")}
            </div>


            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title34")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images2[8]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article38")}
            </div>


            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title35")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images2[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("techniques_list2", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>


        </div>
    )
}