import {useTranslation} from "react-i18next";
import {images} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("guide_dick_pick_title")
    const article = t("guide_dick_pick_article")
    const article2 = t("guide_dick_pick_article1")
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("guide_dick_pick_subtitle1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
        </div>
    )
}

export default Intro