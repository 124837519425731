export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const images1 =[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.8.1.png?alt=media&token=166452ca-2671-4e99-a329-f8b9c2b780ee",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.8.2.png?alt=media&token=c3bf26d5-6510-4239-97b8-e4f664f066fb"
]

