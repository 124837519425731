import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
import {images5} from "../FirstDateRule/constants";
const Tasks = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("navigating_online_dating_subtitle7")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images5[3]} alt="course-preview"/>
            </div>
            <div className="child-container__list">
                {t("navigating_online_dating_list7", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("navigating_online_dating_subtitle8")}
            </div>
            <div className="child-container__list">
                {t("navigating_online_dating_list8", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("navigating_online_dating_note2")}
                </div>
            </div>
        </div>
    )
}

export default Tasks