import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import { WORK} from "../../constants";


const PhoneSex = () => {
    const { t } = useTranslation('app');
    const titleMain = t("work_scenario")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("work") + 0]}
                        objectToSave={{
                            img: WORK[0].img,
                            value: 'work',
                            route: '/code-word',
                            index: 0
                        }}
                        uuid={"work0"}
                        route={"/scenarios/work"}
            />
        <div className="child-container">
            <div className="child-container__list">
                {t("scenarios4_phone_list", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("scenarios4_phone_subtitle")}
            </div>
            <div className="child-container__list">
                {t("scenarios4_phone_list1", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("scenarios4_phone_list2", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
        </div>
            </>
    )
}

export default PhoneSex