import {useTranslation} from "react-i18next";
import {images3} from "./constants";

const Step5 = () => {
    const { t } = useTranslation('app');
    const title = t("her_orgasm_subtitle7")
    const title1 = t("her_orgasm_subtitle8")
    const list = t("her_orgasm_list7", { returnObjects: true })
    const list1 = t("her_orgasm_list8_1", { returnObjects: true })
    const list2 = t("her_orgasm_list8_2", { returnObjects: true })
    return (
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {title}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {title1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[4]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[5]} alt="course-preview" />
            </div>
        </div>
    )
}

export default Step5