import './styles.scss';
import {ReactComponent as ArrowIcon} from "assets/common/arrow.svg";
import {ReactComponent as NoFavoritesIcon} from "assets/app/noFavoritexs.svg";
import {useNavigate} from "react-router";
import {Item} from "../Main/Item";
import {getAuth} from "firebase/auth";
import {useEffect, useState} from "react";
import {getListOfFavorites} from "../../../utils/firebase/userData.utils";
import {useTranslation} from "react-i18next";
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import {MAIN} from "../../../constants/routes.constants";
import cn from "classnames";

type TProps = {
    theme: string
}

export type TFavoritesList = {

    [key: string]:{
    img: string,
    route: string,
    value: string,
    index: string,
    }
}
const FavoritesPage = ({theme}: TProps) => {
    const user = getAuth()
    const { t } = useTranslation('app');
    const [list, setList] = useState<TFavoritesList>({})
    const navigate = useNavigate()
    const getFavorites = async () => {
      const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    const handleRedirect = () => {
        navigate(MAIN)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])

    const getInfo = (idx: string, field: string) => {
        const res = t(field, { returnObjects: true })
        return res[idx]
    }

    return(
        <div className={cn("favorites-container", {empty: !Object.entries(list).length})}>
            <div className="favorites-container__header row-container space-between align-center">
                <ArrowIcon className="tabs-header-container__back" onClick={() => navigate(-1)} />
                <div className="tabs-header-container__title typography-24 typography-bold font-bebas">
                    {t("favorites")}
                </div>
            <div/>
            </div>
            <div className="favorites-container__content column">
                {!!Object.entries(list).length ? Object.entries(list).map(([id, value], idx) => <Item
                    handleRedirect={() => navigate(value.route)}
                    img={value?.img}
                    id={id}
                    route={value?.route}
                    key={'favorites' + idx}
                    value={value.value}
                    active={!!list[id]}
                    onSuccess={getFavorites}
                    info={getInfo(value?.index, value.value)} />) : <div className="favorites-container__content__empty align-center column">
                    <NoFavoritesIcon/>
                    <div className="typography-500 typography-28 mt-24">
                        {t("no_favorites")}
                    </div>
                    <div className="typography-300 typography-14 mt-16">
                        {t("no_favorites_text")}
                    </div>
                </div>}
            </div>
            {!Object.entries(list).length && <Button className="btn-change-password" onClick={handleRedirect} text={t("no_favorites_btn")} buttonTheme={ButtonThemes.DARK} width={100}/>}

        </div>
    )
}

export default FavoritesPage