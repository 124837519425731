import {useTranslation} from "react-i18next";
import { imgUrl } from "./constants";
export const AccordingToMen = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            {new Array(7).fill("").map((_,idx) => {
              return <>
                  <div className="child-container__article-title mt-16 typography-16">
                      {t(`poses_article${21+idx}`)}
                  </div>
                <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl['src'+(21+idx)]} alt="course-preview" />
                </div>

               </>
            })
            }
        </div>
    )
}