export const SCENARIOS_NAMES_ROUTES = [
    "at-home",
    "in-restaurant",
    "guest",
    "work",
    "public"
]

export const AT_HOME = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.1%20Gentle%20Awakening.png?alt=media&token=1f55b984-1dc3-4460-abb9-b92e1c00be51'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.2%20Webcam%20Adventures.png?alt=media&token=7e720bd7-8a22-4e88-8741-692edb3235b7'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.3%20Home%20Video%20Euphoria.png?alt=media&token=abc9a1fc-5ba5-4e0c-87f9-6e967917c44a'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.4%20Story-Fueled%20Encounter.png?alt=media&token=ed742504-0001-45b4-a933-b93858f3dc79'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.5%20Fantasies%20Unleashed.png?alt=media&token=fae60142-7784-4241-8d4d-e163e291517a'
    },
] as const
export const IN_RESTAURANT = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.2.1%20Strangers_%20Ecstasy.png?alt=media&token=69152218-200d-4db4-b82b-e2e96084d77b'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.2.2%20Forbidden%20Desires.png?alt=media&token=a9c2a0c0-778e-4b7b-9b67-a1d8ae68623b'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.2.3%20Dirty%20Pleasure%20Games.png?alt=media&token=81ae13c0-abd2-4d85-bb7a-765017ecb661'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.2.4%20Panty%20Play.png?alt=media&token=f2ffff23-67bd-4cde-9059-39f9723e4ea1'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.2.5%20Fugitive%20Lust.png?alt=media&token=09daece3-6270-4233-8124-2bd908d301da'
    }
] as const
export const GUEST = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.3.1%20Parental%20Control%20Passion.png?alt=media&token=65f6bade-04d4-4d1b-a44f-743197f01f06'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.3.2%20Virgin.png?alt=media&token=96d0a059-049c-4962-989f-bb405b688d53'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.3.3%20Alien%20Allure.png?alt=media&token=773da824-43aa-4e39-924e-865fe70073e4'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.3.4%20The%20Code%20Word.png?alt=media&token=e1742304-e608-4f8b-ad58-8696fa88b35a'
    }
] as const
export const WORK = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.4.1%20Phone%20Pleasures.png?alt=media&token=d936e825-c0ff-4df9-bfd8-79380b107470'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.4.2%20Comfort%20Zone%20Escapade.png?alt=media&token=00abcdd7-f55e-4c21-8765-24fd6a5058fe'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.4.3%20Interview%20Delight.png?alt=media&token=67b0b09c-dc00-4b7f-b7bc-a95e6bb31673'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.4.4%20The%20Business%20Affair.png?alt=media&token=ccc18088-289a-4332-8200-aeb53f4b5525'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.4.5%20The%20Seductive%20Deal.png?alt=media&token=83f529fe-e52f-4fdf-9db6-c9420b8e0110'
    },
] as const
export const PUBLIC = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.5.1%20Rock%20Star%20Revelations.png?alt=media&token=b8bb1921-e8ab-43e8-8928-2e58a9f32ab2'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.5.2%20Beach.png?alt=media&token=b2ff1daf-4ad7-46a2-9658-18e680cca8f6'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.5.3%20Sex%20at%20first%20sight.png?alt=media&token=7ff4a833-0461-4244-aad2-79b960ec7362'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.5.4%20Innocence%20Takes%20a%20Hit.png?alt=media&token=9771d66f-92fe-47df-bb68-e91963471cc5'
    }
] as const


export const AT_HOME_ROUTES = [
    '/gentile',
    '/webcam-model',
    '/home-video',
    '/game-with-story',
    '/fantasy'
]
export const IN_RESTAURANT_ROUTES = [
    '/strangers',
    '/forbidden-device',
    '/dirty-games',
    '/panty-method',
    '/fugitives'
]

export const GUEST_ROUTES = [
    '/parent-control',
    '/virgin',
    '/alien-territory',
    '/code-word',
]
export const WORK_ROUTES = [
    '/phone-sex',
    '/comfort-zone',
    '/interview',
    '/businesswoman',
    '/seductive-deal',
]
export const PUBLIC_ROUTES = [
    '/rock-star',
    '/beach',
    '/first-sight',
    '/insulted-innocence',
]

export const AT_HOME_NUMBER = '0'
export const IN_RESTAURANT_NUMBER = '1'

export const GUEST_NUMBER = '2'
export const WORK_NUMBER = '3'
export const PUBLIC_NUMBER = '4'