import {useTranslation} from "react-i18next";
import { images3} from "./constants";

export const Maid = () => {
    const { t } = useTranslation('app');
    const subtitle = t("role_play_title4")
    const article = t("role_play_article3")
    const list = t("role_play_content3", { returnObjects: true })
    const list1 = t("role_play_content4", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            {list.map((i:{title: string, description: string}, idx: number) =>  {
                return <><div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                    {i?.title}
                </div>
                    <div className="child-container__list">
                        <div className="mt-20 typography-14">
                            {i.description}
                        </div>
                    </div>
                </>})}
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[0]} alt="course-preview" />
            </div>
            {list1.map((i:{title: string, description: string}, idx: number) =>  {
                return <><div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                    {i?.title}
                </div>
                    <div className="child-container__list">
                        <div className="mt-20 typography-14">
                            {i.description}
                        </div>
                    </div>
                </>})}

        </div>
    )
}