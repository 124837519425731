import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {MATURE_INTIMACY_CHALLENGE} from "../../GameItem/contants";


const HardMode = () => {
    const { t } = useTranslation('app');
    const list = t("games3_hard_mode_list", { returnObjects: true })
    const titleMain = t("mature_intimacy_challenge_game")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("mature_intimacy_challenge") + 2]}
                        objectToSave={{
                            img: MATURE_INTIMACY_CHALLENGE[2].img,
                            value: 'mature_intimacy_challenge',
                            route: '/hard-made',
                            index: 2
                        }}
                        uuid={"mature_intimacy_challenge2"}
                        route={"/games/mature-intimacy-challenge"}
            />
        <div className="child-container">
            {list.map((i:{title:"", description:""},idx: number) => {
                return <> <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                    {i.title}
                </div>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i.description}
                    </div>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={imgUrl[idx]} alt="course-preview" />
                    </div>
                </>
            })
            }
        </div>
            </>
    )
}

export default HardMode