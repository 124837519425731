export const START_QUIZ_ROUTE='/age-check';
export const HONEST_CHECK_ROUTE='/honest-check';
export const TEST_BEGINNING_ROUTE='/test-beginning';
export const CONFIDENT_CHECK_ROUTE='/confident-check';
export const CURRENT_RELATIONSHIP_ROUTE='/current-relationship';
export const CANDOR_CHECK_ROUTE='/candor-check';
export const RELATIONSHIP_ISSUE_ROUTE='/relationship-issue';
export const BOREDOM_DISCUSSION_ROUTE='/boredom-discussion';
export const PARTNER_OPENNESS_CHECK_ROUTE='/partner-openness-check';
export const TRUSTING_RELATIONSHIP_ROUTE='/trusting-relationship';
export const DISTANT_RELATIONSHIP_ROUTE='/distant-relationship';
export const EDUCATION_TIME_CHECK_ROUTE='/education-time-check';
export const NEW_READINESS_ROUTE='/new-readiness';
export const SEXUAL_POTENTIAL_ROUTE='/elevate-sexual-potential';
export const ENTICING_ONE_ROUTE='/be-enticing-one';
export const INTIMATE_LIFE_ROUTE='/intimate-life-check';
export const EMAIL_CHECK_ROUTE='/email-check';
export const GOOD_TEST_RESULT_ROUTE='/good-test-result';
export const AVERAGE_TEST_RESULT_ROUTE='/average-test-result';
export const UNSATISFACTORY_TEST_RESULT_ROUTE='/unsatisfactory-test-result';
export const MAIN='/main';
export const COURSES='/courses';
export const GAMES_ROUTE='/games';
export const SCENARIOS_ROUTE='/scenarios';
export const PROFILE_ROUTE='/profile';
export const LANGUAGES_ROUTE='/Languages';
export const SUCCESS_ROUTE='/success';
export const SUCCESS_CHANGE_ROUTE='/success-change';
export const PROGRESS_ROUTE='/progress';
export const FAVORITES_ROUTE='/favourites';
export const PREMIUM_ROUTE='/premium';
export const SUBSCRIPTION_ROUTE='/subscription';
export const SUBSCRIPTION_CANCELLED_ROUTE='/subscription-cancelled';
export const SUBSCRIPTION_FAILED_ROUTE='/subscription-failed';
export const SUBSCRIPTION_SUCCESS_ROUTE='/subscription-success';

export const PAY_INFO_ROUTE='/pay-info';
export const CREATE_ACCOUNT_ROUTE='/create-account';
export const SIGN_UP_OFFER_ROUTE='/sign-up-offer';
export const NEW_LIFE_JOURNEY_ROUTE='/new-life-journey';
export const LOG_IN_ROUTE='/log-in';
export const EMAIL_VERIFICATION_ROUTE='/email-verification';
export const EDIT_PASSWORD_ROUTE='/edit-password';
export const EDIT_NICKNAME_ROUTE='/edit-nickname';

export const COURSE_ROUTES = {
    BUILDING_SELF_CONFIDENCE: '/building-self-confidence',
    POSING: '/posing',
    THE_ART: '/the-art',
    READING_BODY_LANGUAGE: '/reading-body-language',
    APPROACHES_TO_CONNECT: '/approaches-to-connect',
    NAVIGATION_ONLINE: '/navigation-online',
    MAINTAINING: '/maintaining',
    PATHWAYS_TO_SUCCESS: '/pathways-to-success',
    AROUSING_HER_INTERESTS: '/arousing-her-interests',
    FLIRTING_WITH_IMPACT:'/flirting-with-impact',
    MASTERFUL_LOVE: '/masterful-love',
    UNLEASH_YOUR_CHARISMA: '/unleash-your-charisma',
    QUESTIONS:"/questions",
    HER_ORGASM: "/her-orgasm",
    LIBIDO:"/libido",
    MASTERING_EMOTIONAL_CONTROL: '/mastering-emotional-control',
    ESCAPING:'/escaping',
    PREPARATION: '/preparation',
    SIZZLING:'/sizzling',
    PHRASES_FOR_HOT_MESSAGING: '/phrases-for-hot-messaging',
    GUIDE:'/guide',
    SENSUAL_TECHNIQUES:'/sensual-techniques',
    TABOO:'/taboo',
    SECRETS_TECHNIQUES:'/secrets-techniques',
    TURNING_HER:'/turning-her',
    EMBRACING_CUNNILINGUS:'/embracing-cunnilingus',
    SEX_TOYS:"/sex-toys",
    SEX_GAMES:"/sex-games",
    ANAL_SEX:"/anal-sex",
    THREESOME:"/threesome",
    TOP_POSES:"/top-poses",
    SEX_SCENARIOS:"/sex-scenarios",
    BDSM:"/bdsm",
    HOT_PHRASES:"/hot-phrases",
    GUEST:"/guest",
    ROLE_PLAYING: "/role-playing",
    TESTS: "/tests",
    COMMON_TECHNIQUES: "/common-techniques",
    POSES:"/poses",
    BOTTLE:'/bottle',
    TRUTH_OR_DARE:'/truth-or-dare',
    SEX:'/sex',
    ORAL_SEX:'/oral-sex',
    HARD_MODE:'/hard-made',
    TRUTH:'/truth',
    ACTION_PACKED:'/action-packed',
    HOT_TASKS:'/hot-tasks',
    GENTILE:'/gentile',
    WEBCAM_MODEL:'/webcam-model',
    HOME_VIDEO:'/home-video',
    GAME_WITH_STORY:'/game-with-story',
    FANTASY:'/fantasy',
    STRANGERS:'/strangers',
    FORBIDDEN_DEVICE:'/forbidden-device',
    DIRTY_GAMES:'/dirty-games',
    PANTY_METHOD:'/panty-method',
    FUGITIVES:'/fugitives',
    PARENT_CONTROL:'/parent-control',
    VIRGIN:'/virgin',
    ALIEN_TERRITORY:'/alien-territory',
    CODE_WORD:'/code-word',
    PHONE_SEX:'/phone-sex',
    COMFORT_ZONE:'/comfort-zone',
    INTERVIEW:'/interview',
    BUSINESSWOMAN:'/businesswoman',
    SEDUCTIVE_DEAL:'/seductive-deal',
    ROCK_STAR:'/rock-star',
    BEACH:'/beach',
    FIRST_SIGHT:'/first-sight',
    INSULTED_INNOCENCE:'/insulted-innocence',
    SMS:'/sms',
    PRACTICES:'/practices',
    DAYS:'/days',
    SCENARIOS_BDSM:"/scenarios-bdsm",
    LEVEL_1:"/level-1",
    LEVEL_2:"/level-2",
    LEVEL_3:"/level-3",
    LEVEL_4:"/level-4",
    LEVEL_5:"/level-5",
    LEVEL_6:"/level-6",
    LEVEL_7:"/level-7",
    UNFORGETTABLE_CUNNILINGUS_SCENARIOS:"/unforgettable-cunnilingus-scenarios",
    TOP_POSITIONS:"/top-positions",
    BLOWJOB_POSITIONS: "/blowjob-positions",
    MAN_TRUTH: "/man-truth",
    MAN_TRUTH_HARD_MODE: "/man-truth-hard-mode",
    MAN_DARE: "/man-dare",
    MAN_DARE_HARD_MODE:"/man-dare-hard-mode",
    GIRL_TRUTH: "/girl-truth",
    GIRL_TRUTH_HARD_MODE: "/girl-truth-hard-mode",
    GIRL_DARE:"/girl-dare",
    GIRL_DARE_HARD_MODE: "/girl-dare-hard-mode",
    MONTHLY_CHALLENGES: '/monthly-challenges',
    DAYS_OF_SEX_CALENDAR:'/days-of-sex-calendar'
}
