import {useTranslation} from "react-i18next";
import { images1} from "./constants";

const TurnOn = () => {
    const { t } = useTranslation('app');
    const subtitle = t("sizzling_sexting_subtitle3")
    const list = t("sizzling_sexting_list3", { returnObjects: true })
    const list1 = t("sizzling_sexting_list4", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__article-title mt-24 typography-14">
                {t("sizzling_sexting_article3")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
        </div>
    )
}

export default TurnOn