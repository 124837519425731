import {useTranslation} from "react-i18next";
import {src1} from "./constants";

export const Part2 = () => {
    const { t } = useTranslation('app');
    const subtitle = t("threesome_subtitle1")
    const list = t("threesome_list1", { returnObjects: true })
    const list1 = t("threesome_list2", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
        </div>
    )
}