import {useTranslation} from "react-i18next";
import { src1} from "./constants";

const Masks = () => {
    const { t } = useTranslation('app');
    const subtitle = t("bdsm_course_subtitle11")
    const article2 = t("bdsm_course_article11")
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[5]} alt="course-preview" />
            </div>
        </div>
    )
}

export default Masks