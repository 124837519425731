import {Trans, useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './EducationTimeScreen.scss';
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";

export const EducationTimeScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    const relationshipIssuesOptions:string[]= [
        t("educationTimeCheckScreen.tenMinOptionText"),
        t("educationTimeCheckScreen.twentyMinOptionText"),
        t("educationTimeCheckScreen.thirtyMinOptionText"),
        t("educationTimeCheckScreen.sixtyMinOptionText"),];

    return (
        <>
            <p className={'educationTimeCheckQuestion font-bebas typography-32'}>
                <Trans t={t}>{t("educationTimeCheckScreen.question")}</Trans>
            </p>
            <div>
                {relationshipIssuesOptions.map((optionText)=>{
                    return <NextRouteButton key={optionText} option={optionText}
                                            route={nextRoute}
                                            buttonTheme={ButtonThemes.DARK}
                                            className={'educationTimeOptionButton'} />
                })}
            </div>
        </>
    )
}
