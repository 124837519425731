import {useTranslation} from "react-i18next";
import {images8} from "../FirstDateRule/constants";

const Soothsayer = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("arousing_her_interest_subtitle6")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images8[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("arousing_her_interest_list6_1", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__list">
                {t("arousing_her_interest_list6_2", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__list">
                {t("arousing_her_interest_list6_3", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
        </div>
    )
}
export default Soothsayer