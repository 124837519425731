import {useTranslation} from "react-i18next";
import {images} from "./constants";

export const SimulateAllZones = () => {
    const { t } = useTranslation('app');
    const subtitle = t("guide_dick_pick_subtitle2")
    const list1 = t("guide_dick_pick_list1", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("guide_dick_pick_note")}
                </div>
            </div>
        </div>
    )
}