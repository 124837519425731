import {useTranslation} from "react-i18next";
import '../TestResults.scss';
import {ButtonThemes} from "../../../../constants/button.constants";
import {NextRouteButton} from "../../../../components/NextRouteButton/NextRouteButton";
import { ContentContainer } from "../../../../components/onboarding/ContentContainer/ContentContainer";
import {OnBoardingImage} from "../../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {goodTestResult} from "../../../../assets/onBoardingImages";
export const GoodTestResultScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
        <ContentContainer>
            <OnBoardingImage imageSrc={goodTestResult} />
            <h3 className={'testResultsTitle font-bebas'}>{t("goodTestResultsScreen.title")}</h3>
            <p className={'testResultsText'}>{t("goodTestResultsScreen.text")}</p>
        </ContentContainer>
        <NextRouteButton option={t("goodTestResultsScreen.continueButtonText")}
                         route={nextRoute}
                         buttonTheme={ButtonThemes.DARK}
                         className={'testResultsContinueButton'} />
        </>
    )
}
