import {useTranslation} from "react-i18next";
import { images4} from "./constants";

export const MrX = () => {
    const { t } = useTranslation('app');
    const subtitle = t("role_play_title5")
    const article = t("role_play_article4")
    const list = t("role_play_content5", { returnObjects: true })
    const list1 = t("role_play_content6", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            {list.map((i:{title: string, description: string}, idx: number) =>  {
                return <><div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                    {i?.title}
                </div>
                    <div className="child-container__list">
                        <div className="mt-20 typography-14">
                            {i.description}
                        </div>
                    </div>
                </>})}

            <div className="child-container__image mt-24">
                <img loading="lazy" src={images4[0]} alt="course-preview" />
            </div>
            {list1.map((i:{title: string, description: string}, idx: number) =>  {
                return <><div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                    {i?.title}
                </div>
                    <div className="child-container__list">
                        <div className="mt-20 typography-14">
                            {i.description}
                        </div>
                    </div>
                </>})}

        </div>
    )
}