import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {MASTERING_YOUR_MASCULINITY} from "../../CourseItem/contants";
import {getAuth} from "firebase/auth";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {images2} from "./constants";

const TheModel = () => {
    const { t } = useTranslation('app');
    const user = getAuth()
    const [list, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    const title = t("mastering_your_masculinity_course")
    const title1 = t("unleash_your_charisma_title")
    const list1 = t("unleash_your_charisma_list", { returnObjects: true })
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!list[("MASTERING_YOUR_MASCULINITY") + 0]}
                        objectToSave={{
                            img: MASTERING_YOUR_MASCULINITY[0].img,
                            value: 'MASTERING_YOUR_MASCULINITY',
                            route: '/unleash-your-charisma',
                            index: 0
                        }}
                        uuid={"MASTERING_YOUR_MASCULINITY0"}
                        route={"/courses/mastering-your-masculinity"}
            />
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images2[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                    {list1.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
        </div>
            </>
    )
}
export default TheModel