import { getAuth } from 'firebase/auth'
import './style.scss'
import {useEffect, useMemo, useState} from "react";
import {ReactComponent as InfoIcon} from "assets/app/Progress/info.svg";
import {ReactComponent as LearnerIcon} from "assets/app/Progress/learner.svg";
import {ReactComponent as AdventureIcon} from "assets/app/Progress/adventure.svg";
import {ReactComponent as ConnoisseurIcon} from "assets/app/Progress/connoisseur.svg";
import {ReactComponent as SageIcon} from "assets/app/Progress/sage.svg";
import {ReactComponent as ExplorerIcon} from "assets/app/Progress/explorer.svg";
import {getAllUnits, getGeneralProgress, GeneralProgressResponse} from "../../../utils/firebase/userData.utils";
import Item from "./Item";
import PieChart from "./Chart";
import { useTranslation } from 'react-i18next';

export type TProps = {
    theme: string
}

const ProgressPage = ({theme}: TProps) => {
    const user = getAuth();
    const {t} = useTranslation('app');
    const all: {[key: string]: string}[] = t("progress_levels", { returnObjects: true });
    
    const [progress, setProgress] = useState<GeneralProgressResponse>({
        courses: false,
        games: false,
        premium: false,
        scenarios: false
    })
    const getProgress = async() => {
        const res = await getAllUnits(user?.currentUser?.uid)
            setProgress(getGeneralProgress(res))
    }

    const amountPercentage = useMemo(() => {
        return Object.entries(progress)?.filter(([_, val]) => !!val)?.length
    }, [progress])

    const progressChartColor = useMemo(() => ({
        "initial": (theme !== 'light' ? '#f4f4f4' : '#171717'),
        "courses": progress.courses ? (theme !== 'light' ? '#f4f4f4' : '#171717') : '#FF7A00',
        "games":  progress.games && progress.courses ? (theme !== 'light' ? '#f4f4f4' : '#171717') : '#FF7A00',
        "scenarios": progress.games && progress.courses && progress.scenarios ? (theme !== 'light' ? '#f4f4f4' : '#171717') : '#FF7A00',
        "premium": Object.entries(progress)?.every(([_, val]) => !!val) ? (theme !== 'light' ? '#f4f4f4' : '#171717') : '#FF7A00',
    }), [progress])

    useEffect(() => {
        getProgress()
    }, [user])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className="progress-container column">
            <div className="progress-container__title typography-28 typography-bold">{t("progress")}</div>
            <div className="progress-container__chart">
                <div className="progress-container__chart__percentage column align-center">
                    <div className="progress-container__chart__percentage__current typography-30 typography-bold">
                        {Math.floor(100 * (amountPercentage + 1) / 5)}%
                    </div>
                    <div className="progress-container__chart__percentage_all typography-12">
                      / 100%
                    </div>

                </div>
                <PieChart width={280} height={280} items={[
                    {value: 1, color: progressChartColor?.initial, id: "1"},
                    {value: 1, color: progressChartColor?.courses, id: "2"},
                    {value:  +progress.courses, color: progressChartColor?.games, id: "3"},
                    {value:  +progress.games, color: progressChartColor?.scenarios, id: "4"},
                    {value:  +progress.scenarios, color: progressChartColor?.premium, id: "5"},
                    {value: Object.entries(progress)?.filter(([_, val]) => !val)?.length - 1 , color: 'inherit', id: "6"},
                ]} innerRadius={100} outerRadius={140}/>
            </div>


            <div className="progress-container__block-wrapper row-container">
                <InfoIcon className="info-icon" />
                <div className="typography-12 progress-container__block-wrapper__info">
                    {t("information_icon_text")}
                </div>
            </div>
            <div className="progress-container__ranks column">
                <Item
                    title={all[0].title}
                    icon={<ExplorerIcon className="explorer-icon" />}
                    description={all[0].description}
                    disabled={false}
                    active={false}
                />
                <Item
                    title={all[1].title}
                    icon={<LearnerIcon className="learner-icon" />}
                    description={all[1].description}
                    disabled={false}
                    active={!progress.courses}
                />
                <Item
                    title={all[2].title}
                    icon={<AdventureIcon className="adventurer-icon" />}
                    description={all[2].description}
                    disabled={!progress.games}
                    active={progress.games && !progress.scenarios && progress.courses}
                />
                <Item
                    title={all[3].title}
                    icon={<ConnoisseurIcon className="connoisseur-icon" />}
                    description={all[3].description}
                    disabled={!progress.scenarios}
                    active={progress.scenarios && progress.games && progress.courses && !progress.premium}
                />
                <Item
                title={all[4].title}
                icon={<SageIcon className="sage-icon" />}
                description={all[4].description}
                disabled={!progress.premium}
                active={Object.entries(progress)?.every(([_, val]) => !!val)}
            />

            </div>
        </div>
    )
}

export default ProgressPage



