import React, {useContext} from "react";
import Modal from 'react-modal';
import {ThemeContext} from "../../../context/themeContext";
import {ReactComponent as GiftSvg} from '../../../assets/gift.svg';
import {ReactComponent as CloseIcon} from '../../../assets/common/cross.svg';
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import '../../../components/onboarding/PayInfoContainer/PayInfoContainer.scss';
import './PayInfoScreen.scss';

type TProps = {
    setOpen: (val: boolean) => void
    onSubmit: () => void
    isOpen: boolean;
    closeModal: () => void;
    t: Function;

}
const GiftModal = ({setOpen, isOpen, closeModal, t, onSubmit}:TProps) => {

    const { theme } = useContext(ThemeContext);
    return(
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setOpen(false)}
            contentLabel="Example Modal"
            preventScroll={true}
            style={{overlay: {
            background: "rgb(12, 11, 11, 0.8)"}
            }}
            className={"payment-modal gift-modal" + ' ' + theme}
        >
            <div className="payment-modal-content__header-icon">
                <CloseIcon onClick={() => {
                    setOpen(false)
                    closeModal()
                }} />
            </div>
            <div className="row-container align-center justify-center mt-24">
            <GiftSvg />
            </div>
            <div className="row-container justify-center mt-16">
                <div className="gift-text typography-42">{t("payInfoScreen.specialOffer")} </div>
                <div className="typography-42 red-color">&nbsp;60%</div>
            </div>
            <div className="gift-text typography-24 justify-center mt-16">
                {t("payInfoScreen.unlockYourFullPotential")}
            </div>
            <Button text={t("payInfoScreen.get60Discount")} className="mt-24" onClick={() => {
             setOpen(false)
                onSubmit()
            }} buttonTheme={ButtonThemes.DARK} width={100} />
        </Modal>
    )
}

export default GiftModal