import {useTranslation} from "react-i18next";
const Behaviour = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            <div className="child-container__list">
                {t("pathways_list0", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
        </div>
    )
}

export default Behaviour