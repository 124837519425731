export const imgUrl = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.4.1.png?alt=media&token=f255ff29-7251-40f0-812c-1c30a22769b4",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.4.2.png?alt=media&token=a8c4e1f8-92bf-40cb-abd9-3afaab8e1620",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.4.3.png?alt=media&token=26d81b7e-07e8-440b-b1ca-4d23d27fe989",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.4.4.png?alt=media&token=337f364e-04d4-4797-a6d8-32181a0f6662",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.4.5.png?alt=media&token=63e414d4-b030-4450-95d7-0af025a829d8",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.4.6.png?alt=media&token=edaa3ad8-99b1-4910-bccc-dc1091d103ae",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.4.7.png?alt=media&token=700bfa37-a062-4fe5-aa20-2002430428eb",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.4.8.png?alt=media&token=1234b627-bddf-4a01-8313-f45bf71052c1",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.4.9.png?alt=media&token=ba90de39-8356-4e8f-aa99-4a6772e3f1f6"
]