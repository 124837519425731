import {set, ref} from "firebase/database";
import {db, auth} from "../../firebase/firebase.config";
import { signInWithEmailAndPassword } from "firebase/auth";

const technicalUserCredentials = {
    email: 'tech-user@test.test',
    password: 'tech-user@test.test',
}

export const writeUserEmailToDatabase=async (userEmail: string,isNewsletterNeeded:boolean) => {

   await signInWithEmailAndPassword(
        auth,
        technicalUserCredentials.email,
        technicalUserCredentials.password
    )

    const emailLoginPart = Date.now().toString(36) + Math.random().toString(36).substring(2);

    const userRef = ref(db, "usersEmails/" + emailLoginPart);
    try{
        await set(userRef, {
            userEmail,
            isNewsletterNeeded
        })
        await auth.signOut()
    }
    catch(e){
        await auth.signOut()
    }
};

