export const PREMIUM_NAMES_ROUTES = [
    "bdsm-game",
    "truth-or-dare-advanced",
    "days-monthly",
    "days-365",
]

export const BDSM_GAME_ROUTES = [
    "/scenarios-bdsm",
    "/level-1",
    "/level-2",
    "/level-3",
    "/level-4",
    "/level-5",
    "/level-6",
    "/level-7"
]
export const DAYS_MONTHLY_ROUTES = [
    "/monthly-challenges"
]
export const TRUTH_OR_DARE_ADVANCED_ROUTES = [
    "/unforgettable-cunnilingus-scenarios",
    "/top-positions",
    "/blowjob-positions",
    "/man-truth",
    "/man-truth-hard-mode",
    "/man-dare",
    "/man-dare-hard-mode",
    "/girl-truth",
    "/girl-truth-hard-mode",
    "/girl-dare",
    "/girl-dare-hard-mode"
]

export const DAYS_365_ROUTES= [
    "/days-of-sex-calendar/1"
]

export const DAYS_365 =  [
    {
        img: "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F4.1%20Tasks%20(text%20and%20images).png?alt=media&token=0fe9926f-5ae0-4c93-b132-228f206729be"
    }
] as const
export const BDSM_GAME = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.1%20Scenarios.png?alt=media&token=cccc3355-d15c-4d62-b8f5-9aae5bc23243'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.2%20Level%201%20-%20Light%20mode.png?alt=media&token=3049a63c-71cb-4da4-b6b7-34929b8b9ddf'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.3%20Level%202%20-%20Hard%20mode.png?alt=media&token=fca7a4e1-4725-468b-a77e-2da9c450a958'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.4%20Level%203%20-%20Mistress.png?alt=media&token=17746d45-1424-42b8-ac0d-1d05b47775fb'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.5%20Level%204%20-%20Master.png?alt=media&token=2b48cbcb-4599-41d8-b73a-eeea4b9416d1'
    },
    {
        img:"https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.6%20Level%205.png?alt=media&token=f6a7facb-3de3-4476-b5ff-a8f299064c20"
    },
    {
        img: "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.7%20Level%206%20.png?alt=media&token=2c0ae9f9-4f58-4fe8-8a9f-8b34b2ce0e66"
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.8%20Level%207.png?alt=media&token=a4f9daf4-592a-477c-8e37-2f001d9a9114'
    }
] as const


export const DAYS_MONTHLY = [
    { img: "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F3.1%20Tasks%20(text%20and%20images).png?alt=media&token=f4576d4d-6da9-4d3e-a4e1-9db45aa60e90" }
] as const

export const TRUTH_OR_DARE_ADVANCED = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.1%2013%20Unforgettable%20Cunnilingus%20Scenarios.png?alt=media&token=d4ce729e-6c58-47a5-ae26-868c19aca583'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.2%20Top%20Poses%20for%20Deep%20Penetration.png?alt=media&token=6b69d6ac-24ee-4f1a-a782-bd2af434f7de'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.3%20Blowjob%20Poses.png?alt=media&token=5bacabc1-3d61-4757-9ed7-68650be1e114'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.4%20Man%20Truth.png?alt=media&token=2cd0c56d-e0f5-4ece-a4b7-8a8764ceb663'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.5%20Man%20Truth%20Hard%20mode.png?alt=media&token=64fe8008-64ca-40b8-981c-1a84bb17285a'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.6%20Man%20Dare.png?alt=media&token=299850cf-4b87-4ab6-b3fe-bd126f5d2ffc'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.7%20Man%20Dare%20Hard%20mode.png?alt=media&token=f370dea3-6050-4a16-8cc4-3a1a0e95e582'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.8%20Girl%20Truth.png?alt=media&token=e97c2b2e-87a3-4f58-9441-1d69981bda85'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.9%20Girl%20Truth%20Hard%20mode.png?alt=media&token=bbcef2ac-43eb-4dba-8015-8e9aed0fe54f'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.10%20Girl%20Dare.png?alt=media&token=2539da55-9c81-4238-bbbb-c77c2d8fd915'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.11%20Girl%20Dare%20Hard%20mode.png?alt=media&token=42ac86bc-924e-4830-9704-a79b459ef073'
    }
] as const


export const BDSM_GAME_NUMBER = '0'
export const DAYS_MONTHLY_NUMBER  = '2'
export const TRUTH_OR_DARE_ADVANCED_NUMBER  = '1'

export const DAYS_365_NUMBER = '3'

