import {useTranslation} from "react-i18next";
import {src1} from "./constants";

const Anal = () => {
    const { t } = useTranslation('app');
    const subtitle = t("sex_toys_subtitle8")
    const subtitle1 = t("sex_toys_subtitle9")
    const article1 = t("sex_toys_article9")
    const article = t("sex_toys_article8")
   return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[7]} alt="course-preview" />
            </div>
            <div className="child-container__red-title font-bebas  mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article1}
            </div>
        </div>
    )
}

export default Anal