import {useTranslation} from "react-i18next";
import {images} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');

   return(
        <div className="child-container">
            <div className="child-container__list">
                {t("course3_tests_list1", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title1")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title2")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list2", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title3")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title4")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list3", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title5")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title6")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[2]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list4", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title7")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title8")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[3]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list5", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title9")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title10")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[4]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list6", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title11")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title12")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[5]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list7", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title13")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title14")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[6]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list8", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title15")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title16")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[7]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list9", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title17")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title18")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[8]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list10", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title19")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title20")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[9]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list11", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title21")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title22")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[10]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list12", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title23")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title24")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[11]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list13", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title25")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title26")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[12]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list14", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title27")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title28")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[13]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list15", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title29")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_title30")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[14]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list16", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title31")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_article1")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title32")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_article2")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title33")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_article3")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title34")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_article4")}
            </div>

        </div>
    )
}

export default Intro