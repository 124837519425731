import {set, ref, get, remove} from "firebase/database";
import {auth, db} from "../../firebase/firebase.config";
import {signOut} from "firebase/auth";


const initUserProgress  = {
    courses: {
        '0': {
            '0': false,
            '1': false,
            '2': false,
            '3': false,
            '4': false,
            '5': false,
            '6': false,
            '7': false,
            '8': false,
            '9': false
        },
        '1': {
            '0': false,
            '1': false,
            '2': false,
            '3': false,
            '4': false,
            '5': false,
            '6': false,
            '7': false,
            '8': false,
            '9': false,
            '10': false,
            '11': false,
            '12': false,
            '13': false,
            '14': false,
            '15': false,
            '16': false,
            '17': false,
            '18': false,
            '19': false,
            '20': false,
        },
        '2': {
            '0': false,
            '1': false,
            '2': false,
            '3': false,
            '4': false,
            '5': false
        },
        '3': { '0': false, '1': false },
        '4': { '0': false, '1': false }
    },
    games: {
        '0': { '0': false, '1': false },
        '1': { '0': false, '1': false, '2': false },
        '2': { '0': false, '1': false, '2': false }
    },
    scenarios: {
        '0': { '0': false, '1': false, '2': false, '3': false, '4': false },
        '1': { '0': false, '1': false, '2': false, '3': false, '4': false },
        '2': { '0': false, '1': false, '2': false, '3': false},
        '3': { '0': false, '1': false, '2': false, '3': false, '4': false },
        '4': { '0': false, '1': false, '2': false, '3': false}
    },
    premium: {
        '0': {
            '0': false,
            '1': false,
            '2': false,
            '3': false,
            '4': false,
            '5': false,
            '6': false,
            '7': false
        },
        '1': {
            '0': false,
            '1': false,
            '2': false,
            '3': false,
            '4': false,
            '5': false,
            '6': false,
            '7': false,
            '8': false,
            '9': false,
            '10': false
        },
        '2': { '0': false },
        '3': { '0': false }
    }
}
export const writeUserDataToDatabase=async (uid:string, username: string,userEmail:string) => {
    const userRef = ref(db, "usersData/" + uid);

    const res = await set(userRef, {
        userEmail,
        username,
        userProgress: initUserProgress,
        favorites: []
    });
};

export const changeProgressUserDataToDatabase=async (uid:string | undefined, unit: string, id1: string, id2: string  ) => {
    if(!uid) return
    const userRef = ref(db, `usersData/${uid }/userProgress/${unit}/${id1}/${id2}`);

    await set(userRef, true);
};

export const getProgressUserDataToDatabase=async (uid:string | undefined, unit: string, id1: string ) => {
    if(!uid) return
    const userRef = ref(db, `usersData/${uid }/userProgress/${unit}/${id1}`);
    const res  = await get(userRef)
    return res.val()  || []
};
export const getUnitUserDataToDatabase=async (uid:string | undefined, unit: string) => {
    if(!uid) return
    const userRef = ref(db, `usersData/${uid }/userProgress/${unit}`);
    const res  = await get(userRef)
    return res.val()
};

export const getAllUnits=async (uid:string | undefined) => {
    if(!uid) return
    const userRef = ref(db, `usersData/${uid}/userProgress`);
    const res  = await get(userRef)
    return res.val()
};
export const getUserData=async (uid:string | undefined) => {
    if(!uid) return
    const userRef = ref(db, `usersData/${uid}`);
    const res  = await get(userRef)
    return res.val()
};
export const logOutRequest =async (uid:string | undefined, callBack: () => void) => {
    if(!uid) return
    await signOut(auth)
    callBack()
};


export const getListOfFavorites=async (uid:string | undefined) => {
    if(!uid) return
    const userRef = ref(db, `usersData/${uid }/favorites`);
    const res  = await get(userRef)
    return res.val() || []
};
export const setFavoriteToList=async (uid:string | undefined, id: string, value: any) => {
    if(!uid) return
    const fav = ref(db, `usersData/${uid }/favorites/${id}`);
    const favItem  = await get(fav)
    if(favItem.exists()) {
        await remove(fav)
    } else {
        await set(fav, value)
    }

};

export type ProgressCalcResponse = { sumOfViewed: number, sumOfItems: number };

export const calculateCourseProgress = (res: boolean[]): ProgressCalcResponse => {
    return {
        sumOfViewed: res?.filter(item => item)?.length || 0,
        sumOfItems: res?.length || 0
    };
}

export const calculateUnitProgress = (res: boolean[][]): ProgressCalcResponse => {
    return res?.reduce((acc: ProgressCalcResponse, items) => {
        return {
            sumOfViewed: (acc?.sumOfViewed || 0)+ items.filter(item => item).length,
            sumOfItems: (acc?.sumOfItems || 0) + items?.length
        }
    }, { sumOfViewed: 0, sumOfItems: 0 });
}

export const calculateCoursesProgressItem = (res: boolean[][]): ProgressCalcResponse[] => {
    return res.map(items => ({
        sumOfViewed: items?.filter(item => item).length,
        sumOfItems: items?.length
    }));
}

type UserProgressResponse =  { courses: boolean[][], games: boolean[][], premium: boolean[][], scenarios: boolean[][] };
export type GeneralProgressResponse = { courses: boolean, games: boolean, premium: boolean, scenarios: boolean };

export const getGeneralProgress = (res: UserProgressResponse): GeneralProgressResponse => {
    return {
        courses: res["courses"].every((items) => items.filter(item => item).length === items.length),
        games: res["games"].every((items) => items.filter(item => item).length === items.length),
        premium: res["premium"].every((items) => items.filter(item => item).length === items.length),
        scenarios: res["scenarios"].every((items) => items.filter(item => item).length === items.length)
    }
}

export const getScore = (res: UserProgressResponse): number => {
    return Object.entries(res)?.reduce((score, [_key, unit]) => {
        score += unit?.reduce((acc, items) => {
            acc += items?.filter(item => item)?.length * 10;
            return acc;
        }, 0);

        return score;
    }, 0);
}
