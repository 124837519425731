import {useTranslation} from "react-i18next";
import  {images1} from "./constants";

export const Massage = () => {
    const { t } = useTranslation('app');
 return(
        <div className="child-container">
            <div className="child-container__article-title mt-24 typography-16">
                {t("techniques_article19")}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title16")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={images1[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article20")}
            </div>

            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title17")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article21")}
            </div>

            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title18")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article22")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title19")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article23")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title20")}
            </div>

            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[1]} alt="course-preview" />
            </div>

            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article24")}
            </div>


            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title21")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article25")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title22")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article26")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title23")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article27")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title24")}
            </div>

            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article28")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title25")}
            </div>

            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[2]} alt="course-preview" />
            </div>

            <div className="child-container__list">
                {t("techniques_list1", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>


        </div>
    )
}