export const dateIdeas='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FUnleashing%20date%20ideas%20that%20make%20a%20statement.png?alt=media&token=050463b2-103e-4088-a864-0c57edd92de9'
export const arousingSMS='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FMastering%2069%20tantalizing%20text%20messages.png?alt=media&token=32e529a1-1c6d-45f7-8494-08a2732ded8a'
export const awakenSexuality='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FPractices%20that%20unleash%20your%20unwavering%20confidence.png?alt=media&token=438644e8-67a4-4ae1-948e-4ecfcb3ea168'
export const boostingLibido='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2F30%20Challenges%20to%20boost%20your%20masculinity%20over%20a%20month.png?alt=media&token=8c19ed0c-40fb-4ede-98b3-d562a629dd48'
export const howToEngage='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FCrushing%20conversations%20with%20women%20you%20desire.png?alt=media&token=ff59c1e3-80c1-4dc2-a1de-0f321f803bd6'
export const methodsOfConnection='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FTactics%20for%20dominating%20on%20social%20media%20and%20dating%20apps.png?alt=media&token=fb6bd7d4-38de-465f-9247-2b37965f8123'
export const sexScenarios='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FPractices%20that%20unleash%20your%20unwavering%20confidence.png?alt=media&token=438644e8-67a4-4ae1-948e-4ecfcb3ea168'
export const signsOfMan='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FThe%20undeniable%20traits%20of%20a%20high-quality%20woman.png?alt=media&token=67a49f57-6761-401a-8801-3708a558a331'
export const socialMediaMan='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FThe%20art%20of%20identifying%20successful%20women%20on%20social%20media.png?alt=media&token=1d5b5aec-d4d6-493d-b03b-cb5f5f133546'
export const waysToMeetMan='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FStrategies%20for%20seizing%20opportunities%20with%20women%20in%20any%20situation.png?alt=media&token=7d95c071-6232-48aa-8f41-95fad265febc'


