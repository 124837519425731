import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './EmailVerificationScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {Footer} from "../../../components/lego/Footer/Footer";
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";

export const EmailVerificationScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
            <div>
            <ContentContainer>
                <h4 className={'emailVerificationTitle'}>{t('verifyEmailScreen.title')}</h4>
                <h6 className={'emailVerificationText'}>{t('verifyEmailScreen.text')}</h6>
            </ContentContainer>
            <NextRouteButton option={t('verifyEmailScreen.continueButton')}
                             route={nextRoute}
                             buttonTheme={ButtonThemes.DARK}
                             className={'EmailVerificationButton'} />
            </div>
            <Footer text={<span className="">
                    {t("privatePolicyText")}
                <a href={PolicyAnchors.TERMS}>{t("createAccountScreen.policyAnchors.terms")}</a>      &nbsp;
                <a href={PolicyAnchors.PRIVACY}>{t("createAccountScreen.policyAnchors.privacy")}</a>{' '}      &nbsp;
                <a href={PolicyAnchors.SUBSCRIPTION}>{t("createAccountScreen.policyAnchors.subscription")}</a>      &nbsp;
                </span>}/>
        </>
    )
}