import {useNavigate} from "react-router";
import '../../../components/TabsHeader/style.scss'
import {useTranslation} from "react-i18next";
import { PROFILE_ROUTE} from "../../../constants/routes.constants";
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import './styles.scss';
import React from "react";
import {ReactComponent as ArrowIcon} from "assets/common/arrow.svg";

const Subscription = () => {
    const navigate = useNavigate()
    const { t } = useTranslation('app');

    const onSend=()=>{
        navigate(PROFILE_ROUTE)
    }

    return (
        <div  className="languages-wrapper subscription success-page">
            <div className="tabs-header-container row-container space-between align-center">
                <ArrowIcon className="tabs-header-container__back" onClick={() => navigate(-1)} />
                <div className="tabs-header-container__title typography-24 typography-bold">
                    {t('subscription')}
                </div>
                <div/>
            </div>
            <div className="languages-wrapper__content">
            <div className="success-container column align-center">
                <div className="success-container__title typography-20 typography-500">
                    {t("SubscriptionStatus")}
                </div>
                <div className="success-container__active-since typography-14 mt-16">
                    {t("activeSince")}
                    <span className="success-container__active-since__date">00.00.0000</span>
                </div>
            </div>
            <Button className="btn-change-password" onClick={onSend} text={t("cancelSubscriptionBtn")} buttonTheme={ButtonThemes.DARK} width={100}/>
            </div>
        </div>
    )
}

export default Subscription