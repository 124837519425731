export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.2.1.png?alt=media&token=42d92bbd-392f-49a2-bb96-7bd530a697d4",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.2.2.png?alt=media&token=7a0c84dd-0154-4f32-acec-b6ab37dd5a64",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.2.3.png?alt=media&token=eef232db-95be-4745-8725-db4d37a7007e",
]





