import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './RelationshipIssuesScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";

export const RelationshipIssuesScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    const relationshipIssuesOptions:string[]= [
        t("relationshipIssuesScreen.noCommonInterestsIssueText"),
        t("relationshipIssuesScreen.understandingIssueText"),
        t("relationshipIssuesScreen.domesticIssueText"),
        t("relationshipIssuesScreen.boredomIssueText"),
        t("relationshipIssuesScreen.interestIssueText"),];

    return (
        <>
            <ContentContainer>
                <h6 className={'relationshipIssueQuestion font-bebas typography-20 typography-400'}>{t("relationshipIssuesScreen.question")}</h6>
            </ContentContainer>
            <div>
                {relationshipIssuesOptions.map((optionText)=>{
                  return <NextRouteButton key={optionText} option={optionText}
                                     route={nextRoute}
                                     buttonTheme={ButtonThemes.DARK}
                                     className={'relationshipIssueOptionButton'} />
                })}
            </div>
        </>
    )
}
