import {InfoDarkBox} from "../../InfoDarkBox/InfoDarkBox";
import {IInfoBoxData} from "../../../types/infoDarkBox.types";
import {TFunction} from "i18next";
import {ReactComponent as SexCalendarLogo } from "assets/app/SexLogo.svg";
import {ReactComponent as TopPositionsLogo } from "assets/app/TopPositions.svg";
import {ReactComponent as MasturbationLogo } from "assets/app/Masturbation.svg";
import {ReactComponent as OralSexLogo } from "assets/app/OralSex.svg";
import {ReactComponent as MalualLogo } from "assets/app/Malual.svg";
import {ReactComponent as HardModeLogo } from "assets/app/HardMode.svg";
import {ReactComponent as TipsLogo } from "assets/app/Tips.svg";
import {ReactComponent as UltimateLogo } from "assets/app/Ultimate.svg";
import {ReactComponent as WeeklyLogo } from "assets/app/Weekly.svg";

export const SubscriptionBenefits =({t}:{t:TFunction})=>{
    const subscriptionBenefitsData:IInfoBoxData[]=[
        {logoSrc: <SexCalendarLogo />,title:t("payInfoScreen.whatYouGet.sexCalendarText")},
        {logoSrc:<TopPositionsLogo/>,title:t("payInfoScreen.whatYouGet.topPositionsText")},
        {logoSrc:<MasturbationLogo/>,title:t("payInfoScreen.whatYouGet.masturbationTechniquesText")},
        {logoSrc:<OralSexLogo/>,title:t("payInfoScreen.whatYouGet.oralSexText")},
        {logoSrc:<MalualLogo/>,title:t("payInfoScreen.whatYouGet.manualTechniquesText")},
        {logoSrc:<WeeklyLogo/>,title:t("payInfoScreen.whatYouGet.guideDuringSexText")},
        {logoSrc:<HardModeLogo/>,title:t("payInfoScreen.whatYouGet.hardModeText")},
        {logoSrc:<UltimateLogo/>,title:t("payInfoScreen.whatYouGet.cunnilingusText")},
        {logoSrc:<TipsLogo/>,title:t("payInfoScreen.whatYouGet.tenTipsForExcitement")}
    ]

    return (
        <InfoDarkBox infoData={subscriptionBenefitsData} title={t("payInfoScreen.whatYouGet.title")}
                     infoFooterText={t("payInfoScreen.whatYouGet.manyMoreText")}/>
    )
}
