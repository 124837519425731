import {useTranslation} from "react-i18next";
import { images10} from "./constants";

export const Husband = () => {
    const { t } = useTranslation('app');
    const list2 = t("role_play_list15", { returnObjects: true })
    const list1 = t("role_play_list16", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images10[0]} alt="course-preview" />
            </div>

             <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
        </div>
        </div>
    )
}