import {useTranslation} from "react-i18next";
import { images6} from "./constants";

const MaintainingEngaging = () => {
    const { t } = useTranslation('app');
    const title = t("maintaining_engaging_conversations_title")
    const list = t("maintaining_engaging_conversations_list", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            {list.map((i:{title: string, description: string}, idx: number) =>  {
                return <><div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                    {i?.title}
                </div>
                     <div className="child-container__image mt-24">
                        <img loading="lazy" src={images6[idx]} alt="course-preview"/>
                    </div>
                    <div className="child-container__list">
                        <div className="mt-20 typography-14">
                            {i.description}
                        </div>
                    </div>
                </>})}

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("maintaining_engaging_conversations_subtitle1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images6[8]} alt="course-preview"/>
            </div>
            <div className="child-container__list">
                {t("maintaining_engaging_conversations_list1", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("maintaining_engaging_conversations_subtitle2")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images6[9]} alt="course-preview"/>
            </div>
            <div className="child-container__list">
                {t("maintaining_engaging_conversations_list2", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("maintaining_engaging_conversations_subtitle3")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images6[10]} alt="course-preview"/>
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("maintaining_engaging_conversations_article3")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("maintaining_engaging_conversations_subtitle4")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images6[11]} alt="course-preview"/>
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("maintaining_engaging_conversations_article4")}
            </div>

        </div>
    )
}
export default MaintainingEngaging