export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const imgs = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1.1.png?alt=media&token=78ffbd9f-94a9-42b8-b3d3-4b30906779f9",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1.2.png?alt=media&token=f2a8cc51-0a77-4ff6-9198-da91218d9344",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1.3.png?alt=media&token=483b915b-edb8-4ff4-b886-f34fe777e7bf",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1.4.png?alt=media&token=cc9ab6c8-6ec1-4468-ac8d-c00fef788017",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1.5.png?alt=media&token=540b6ee4-bdf2-4290-bdd9-95a9e771eccb",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1.6.png?alt=media&token=b3090ff0-f34a-4d5c-8a01-12467182c96e",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1.7.png?alt=media&token=56b2610a-1ce1-438f-9b6b-ef671617a3e5",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1.8.png?alt=media&token=e652a947-24b4-4f37-83f3-9772fc3ba322",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1.9.png?alt=media&token=6799333d-d7bb-41be-b7fe-7084ec826b2e",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1.10.png?alt=media&token=46c60ca6-24da-4d66-81a3-81363cf65f9e"
]
