import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import { DECIDE_YOUR_DESTINY} from "../../GameItem/contants";
import {getImage} from "../../../../utils/firebase/storage";


const Truth = () => {
    const { t } = useTranslation('app');
    const title = t("games4_truth_or_dare_title")
    const list = t("games4_truth_or_dare_list", { returnObjects: true })
    const title1 = t("games4_truth_or_dare_title1")
    const list1 = t("games4_truth_or_dare_list1", { returnObjects: true })
    const titleMain = t("decide_your_destiny_game")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("decide_your_destiny") + 0]}
                        objectToSave={{
                            img: DECIDE_YOUR_DESTINY[0].img,
                            value: 'decide_your_destiny',
                            route: '/hard-made',
                            index: 0
                        }}
                        uuid={"decide_your_destiny0"}
                        route={"/games/decide-your-destiny"}
            />
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            {list.map((i: string,idx:number) => {
                return <> <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                    {i}
                </div>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={imgUrl[1+idx]} alt="course-preview" />
                    </div>
                </>
            })
            }
            <div className="child-container__title font-bebas mt-24 typography-20">
                {title1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[66]} alt="course-preview" />
            </div>
            {list1.map((i: string,idx:number) => {
                return <> <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                    {i}
                </div>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={imgUrl[67+idx]} alt="course-preview" />
                    </div>
                </>
            })
            }
        </div>
            </>
    )
}

export default Truth