import {useTranslation} from "react-i18next";
import {images8} from "../FirstDateRule/constants";

const Origami = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("arousing_her_interest_subtitle1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images8[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("arousing_her_interest_article1")}
            </div>
        </div>
    )
}
export default Origami