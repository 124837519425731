import {useTranslation} from "react-i18next";

const Lingerie = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("arousing_her_interest_subtitle4")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("arousing_her_interest_article4")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("arousing_her_interest_subtitle5")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("arousing_her_interest_article5")}
            </div>
        </div>
    )
}
export default Lingerie