export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
]

export const src1=[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.16.1.png?alt=media&token=aacc0f65-31c8-4810-bd99-d2fb0b664e85",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.16.2.png?alt=media&token=4130bcc0-f3a4-4d0a-8f4c-a61a0400cdf2",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.16.3.png?alt=media&token=2ab93287-396c-45f1-8807-c43f30b3a3b2",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.16.4.png?alt=media&token=f55cb679-8872-44e5-bfcf-30c27d407b51",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.16.5.png?alt=media&token=b5fa18f5-589b-475a-8103-f9fbf83d05ee",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.16.6.png?alt=media&token=0eb81c08-48ad-4b8a-a79d-b75396f16e8f",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.16.7.png?alt=media&token=655ad46c-86a1-4bbc-af64-48bfe1e8ea27",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.16.8.png?alt=media&token=b322343d-496f-455e-bde1-334cac0b19b8",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.16.9.png?alt=media&token=2bdb8ede-fb2c-40f0-86f1-ed41747cb405"
]
