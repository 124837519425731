export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.1.1.png?alt=media&token=36c52915-1051-4426-b7a3-a4354a0fda85",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.1.2.png?alt=media&token=c0cd3213-7412-4980-ad90-67fa58773018",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.1.3.png?alt=media&token=9eb27bd1-7199-452f-a513-4053c9863f26",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1.1.4.png?alt=media&token=3527db91-c440-46f1-939e-61c9499df3e6",
]





