import {useTranslation} from "react-i18next";
import { images5} from "./constants";

export const Taxi = () => {
    const { t } = useTranslation('app');
    const article = t("role_play_article5")
    const list = t("role_play_list8", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images5[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
        </div>
    )
}