import {useTranslation} from "react-i18next";

const Intrigue = () => {
    const { t } = useTranslation('app');
    const title = t("arousing_her_interest_title")
    const article = t("arousing_her_interest_article")
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__list">
                <div className="child-container__list mt-24 typography-14">{article}</div>
            </div>
        </div>
    )
}
export default Intrigue