import {
    GAMES_ROUTE,
    PREMIUM_ROUTE,
    SCENARIOS_ROUTE,
    COURSES as COURSE_ROUTE
} from "../../../constants/routes.constants";
import {SCENARIOS_NAMES_ROUTES} from "../Scenarios/constants";
import {PREMIUM_NAMES_ROUTES} from "../PremiumItem/contants";
import {GAMES_NAMES_ROUTES} from "../Games/constants";
import {COURSES_NAMES_ROUTES} from "../Courses/constants";

export const COURSES = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.%20Mastering%20Your%20Masculinity%20Course%20.png?alt=media&token=47b5e89f-18be-4f5b-b017-de00aa910a76',
        id: 'course_1',
        url: COURSE_ROUTE + '/' + COURSES_NAMES_ROUTES[0]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.%20Pleasure%20Pathways%20Course.png?alt=media&token=0c439a83-726e-46e1-8332-1585a98ae679',
        id: 'course_2',
        url: COURSE_ROUTE + '/' + COURSES_NAMES_ROUTES[1]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.%20Erotic%20Mastery%20and%20Relationship%20Advancement%20Course.png?alt=media&token=82d1337f-3647-479a-a471-5a07ec227f32',
        id: 'course_3',
        url: COURSE_ROUTE + '/' + COURSES_NAMES_ROUTES[2]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2F30%20Days%2F4.%2030%20Day%20Workout%20Challenge%20%20Course.png?alt=media&token=ec95aaf9-7279-4cb4-a3a6-0530000a0807',
        id: 'course_4',
        url: COURSE_ROUTE + '/' + COURSES_NAMES_ROUTES[3]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2F69%2F69%20Arousing%20SMS.png?alt=media&token=7cbe5f1c-c15f-4345-9e4a-6af6997fa351',
        id: 'course_5',
        url: COURSES_NAMES_ROUTES[4]
    }
]
export const GAMES = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FBonding%2F5.%20Bonding%20Adventures%20for%20Couples.png?alt=media&token=3478b8a7-759f-4407-9a0d-73f6571edbf2',
        id: 'games_1',
        url: GAMES_ROUTE + '/' + GAMES_NAMES_ROUTES[0]
    },
    {
        img:'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.Mature%20Intimacy%20Challenge.png?alt=media&token=f29a724a-d190-46b6-961f-3367b2ac6d5e',
        id: 'games_2',
        url: GAMES_ROUTE + '/' + GAMES_NAMES_ROUTES[1]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.%20Decide%20Your%20Destiny.png?alt=media&token=7474046d-442b-4d06-adc0-199082e0bc3e',
        id: 'games_3',
        url: GAMES_ROUTE + '/' + GAMES_NAMES_ROUTES[2]
    }
]

export const SCENARIOS = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.1%20At%20Home_Hotel.png?alt=media&token=2a4f5f90-8e4e-448a-963d-5645d79cf2ec',
        id: 'scenarios_1',
        url:SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[0]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.2.1%20Strangers_%20Ecstasy.png?alt=media&token=69152218-200d-4db4-b82b-e2e96084d77b',
        id: 'scenarios_2',
        url:SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[1]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.3.1%20Parental%20Control%20Passion.png?alt=media&token=65f6bade-04d4-4d1b-a44f-743197f01f06',
        id: 'scenarios_3',
        url:SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[2]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.4.1%20Phone%20Pleasures.png?alt=media&token=d936e825-c0ff-4df9-bfd8-79380b107470',
        id: 'scenarios_4',
        url:SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[3]
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Exploration%2F8.5.1%20Rock%20Star%20Revelations.png?alt=media&token=b8bb1921-e8ab-43e8-8928-2e58a9f32ab2',
        id: 'scenarios_5',
        url:SCENARIOS_ROUTE + '/' + SCENARIOS_NAMES_ROUTES[4]
    }
]


export const PREMIUM = [
    {
        img: "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.%20BDSM%20Game.png?alt=media&token=007cd5eb-4b8b-4a22-9c84-2c480649c860",
        id: 'premium_1',
        url: PREMIUM_ROUTE + '/' + PREMIUM_NAMES_ROUTES[0]
    },
    {
        img: "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F2.%20%E2%80%9CTruth%20or%20Dare%E2%80%9D%20Advanced%20.png?alt=media&token=2e7be511-d139-42e6-b610-cf4b1f9cef77",
        id: 'premium_2',
        url: PREMIUM_ROUTE + '/' + PREMIUM_NAMES_ROUTES[1]
    },
    {
        img:"https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F3.%2030%20Monthly%20Challenges%20to%20Boost%20Your%20Libido.png?alt=media&token=600347cc-4917-403a-88c1-621fd94235a2",
        id: 'premium_3',
        url: PREMIUM_ROUTE + '/' + PREMIUM_NAMES_ROUTES[2]
    },
    {
        img: "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F4.%20365%20Days%20of%20Sex%20Calendar.png?alt=media&token=82c0cac3-34ab-461b-9640-d296ff419047",
        id: 'premium_4',
        url: PREMIUM_ROUTE + '/' + PREMIUM_NAMES_ROUTES[3]
    }
]