import {useTranslation} from "react-i18next";
import {src1} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PLEASURE_PATHWAYS} from "../../../CourseItem/contants";

const  RidOfTheGag = () => {
    const { t } = useTranslation('app');
    const title = t("embracing_cunnilingus_title")
    const list = t("embracing_cunnilingus_list", { returnObjects: true })
    const list1 = t("embracing_cunnilingus_list1", { returnObjects: true })
    const list2 = t("embracing_cunnilingus_list3", { returnObjects: true })
    const titleMain = t("pleasure_pathways_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("pleasure_pathways") + 14]}
                        objectToSave={{
                            img: PLEASURE_PATHWAYS[14].img,
                            value: 'pleasure_pathways',
                            route: '/embracing-cunnilingus',
                            index: 14
                        }}
                        uuid={"pleasure_pathways14"}
                        route={"/courses/pleasure-pathways"}
            />
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
        </div>
            </>
    )
}

export default RidOfTheGag