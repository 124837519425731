export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const images =[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.1.png?alt=media&token=43d19979-5fcf-4ed2-b916-6e7f70678ee9",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.2.png?alt=media&token=88a1cf18-17e1-47f7-8fe1-807e2d95e16c",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.3.png?alt=media&token=0e5aa5fa-3e09-4c65-b334-f7a0ab3537bb",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.4.png?alt=media&token=9a997de8-27b8-47d5-8623-d4b08ff28329",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.5.png?alt=media&token=dee7f9a3-cbfa-411d-9229-c5209b1f8185",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.6.png?alt=media&token=461a1ff5-cbf5-4b3b-9d4b-6c5703d07576",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.7.png?alt=media&token=0f91cb71-ad1b-4a09-8cbd-ef2722b3ee25",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.8.png?alt=media&token=1b3bafdb-276f-4efe-9615-fe03202de5a0",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.9.png?alt=media&token=9233baef-b3fc-427d-8391-3a9dd4815828",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.10.png?alt=media&token=07f89f85-4f74-435c-8b39-2d68f93c5616",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.11.png?alt=media&token=e3cbb959-18c3-433c-8cab-f05c8d83fe82",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.12.png?alt=media&token=0f840c3a-7c1b-46f5-9ce8-b87f92815e7d",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.13.png?alt=media&token=0f2c40aa-392b-40cd-82ed-9fcae4cfad5a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.14.png?alt=media&token=316f07b8-ed8d-46c6-8814-ca3f6cb78ed3",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.1.15.png?alt=media&token=2ec82ee5-de40-4351-985f-d43a70f8d300"
]

export const images1 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.1.png?alt=media&token=53951356-a1a2-47ed-943a-ac6f80c92c6a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.2.png?alt=media&token=9d942f81-78dc-4850-92f2-0f4d0943ef9f",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.3.png?alt=media&token=12c078bf-48aa-4a02-b9ff-896ed10c9190",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.4.png?alt=media&token=b21c66bb-dea8-4be3-a4aa-65d3053a2b32",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.5.png?alt=media&token=da6094e7-6577-4f68-bf19-3bac392c9b38",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.6.png?alt=media&token=b2beda7f-97c0-41a8-b969-bb34bd53e87a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.7.png?alt=media&token=bee44606-40df-4e63-997c-ebf9039bbc03",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.8.png?alt=media&token=231b2cb8-7558-4dc7-9b6d-021c6e9e63ea",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.9.png?alt=media&token=09cc573e-ebff-4364-a688-c6a1c14ccd15",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.10.png?alt=media&token=a373a729-972f-46cd-a57d-e8b43d0e2d87",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.11.png?alt=media&token=43f78af6-c9c0-4a3a-a09a-6a7605f0786b",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.12.png?alt=media&token=48fa191e-8057-44db-9de1-d4299ef78001",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.13.png?alt=media&token=4b9ce8cb-cc32-4e09-a0ad-2220a11fe98c",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.14.png?alt=media&token=e77b2fca-1809-4be0-8c5c-39a8127d9073",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4.2.15.png?alt=media&token=51a6e781-98f5-42f0-872d-e3740694d9de"
]
