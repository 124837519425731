import {Trans, useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './IntimateLifeScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {
    AVERAGE_TEST_RESULT_ROUTE,
    GOOD_TEST_RESULT_ROUTE,
    UNSATISFACTORY_TEST_RESULT_ROUTE
} from "../../../constants/routes.constants";

interface IIntimateLifeProps {
    nextRoute:string,
    setTestResultRoute:(route:string)=>void
}

interface IIntimateLifeOptions {
    option: string;
    testResultRoute :string;
}
export const IntimateLifeScreen =({nextRoute, setTestResultRoute}:IIntimateLifeProps)=>{
    const { t } = useTranslation('onboarding');

    const intimateLifeOptions:IIntimateLifeOptions[]= [
        {option: t("intimateLifeCheckScreen.excellentOptionText"),
            testResultRoute: GOOD_TEST_RESULT_ROUTE},
        {option: t("intimateLifeCheckScreen.goodOptionText"),
            testResultRoute: GOOD_TEST_RESULT_ROUTE},
        {option:t("intimateLifeCheckScreen.averageMinOptionText"),
            testResultRoute: AVERAGE_TEST_RESULT_ROUTE},
        {option:t("intimateLifeCheckScreen.needWorkOptionText"),
            testResultRoute: UNSATISFACTORY_TEST_RESULT_ROUTE},
        {option: t("intimateLifeCheckScreen.unsatisfactoryOptionText"),
            testResultRoute: UNSATISFACTORY_TEST_RESULT_ROUTE}];

    const setFinalTestResultRoute= (route:string)=>{
        setTestResultRoute(route)
    }

    return (
        <>
            <ContentContainer>
                <h3 className={'intimateLifeQuestionTitle  font-bebas'}>{t("intimateLifeCheckScreen.title")}</h3>
                <p className={'intimateLifeQuestion'}>
                    <Trans t={t}>{t("intimateLifeCheckScreen.rateText")}</Trans>
                </p>
            </ContentContainer>
            <div>
                {intimateLifeOptions.map(({option,testResultRoute})=>{
                    return <NextRouteButton key={option} option={option}
                                            route={nextRoute}
                                            buttonTheme={ButtonThemes.DARK}
                                            className={'intimateLifeCheckOptionButton'}
                                            onClick={()=>setFinalTestResultRoute(testResultRoute)}/>
                })}
            </div>
        </>
    )
}
