import {useTranslation} from "react-i18next";
import './styles.scss'
import { images1} from "./constants";
const Behaviour = () => {
    const { t } = useTranslation('app');
    const title = t("mastering_your_masculinity1_title")
    const list1 = t("mastering_your_masculinity1_list1", { returnObjects: true })
    const list2 = t("mastering_your_masculinity1_list2", { returnObjects: true })
    const list3 = t("mastering_your_masculinity1_list3", { returnObjects: true })
    const list4 = t("mastering_your_masculinity1_list4", { returnObjects: true })
    const list5 = t("mastering_your_masculinity1_list4_1", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__list">
                {list1.map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("mastering_your_masculinity1_subtitle1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list2.map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("mastering_your_masculinity1_subtitle2")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                <ul>
                    {list3.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("mastering_your_masculinity1_subtitle3")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[2]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list4.map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {t("mastering_your_masculinity1_subtitle4")}
            </div>
            <div className="child-container__list">
                <ul>
                    {list5.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("mastering_your_masculinity1_subtitle5")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[3]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("mastering_your_masculinity1_list5_1", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__list">
                <ul>
                    {t("mastering_your_masculinity1_list5_2", {returnObjects: true}).map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("mastering_your_masculinity1_subtitle6")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[4]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("mastering_your_masculinity1_list6_1", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__list">
                <ul>
                    {t("mastering_your_masculinity1_list6_2", {returnObjects: true}).map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("mastering_your_masculinity1_subtitle7")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[5]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("mastering_your_masculinity1_list7_1", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__list">
                <ul>
                    {t("mastering_your_masculinity1_list7_2", {returnObjects: true}).map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("mastering_your_masculinity1_subtitle8")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[6]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("mastering_your_masculinity1_list8_1", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__list">
                <ul>
                    {t("mastering_your_masculinity1_list8_2", {returnObjects: true}).map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("mastering_your_masculinity1_note1")}
                </div>
            </div>
        </div>
    )
}

export default Behaviour