import { PREMIUM} from "../Main/constants";
import {Item} from "../Main/Item";
import {PREMIUM_ROUTE} from "../../../constants/routes.constants";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import '../styles/styles.scss'
import {PREMIUM_NAMES_ROUTES} from "./contants";
import {useCallback, useEffect, useState} from "react";
import {
    calculateCoursesProgressItem, getListOfFavorites,
    getUnitUserDataToDatabase,
    ProgressCalcResponse
} from "../../../utils/firebase/userData.utils";
import {getAuth} from "firebase/auth";
import {TFavoritesList} from "../FavoritesPage/FavoritesPage";
import cn from "classnames";
export const Premium = () => {
    const navigate=useNavigate();
    const user = getAuth()
    const { t } = useTranslation('app');
    const premiumInfo = t('premium_info', { returnObjects: true })
    const premiumTitle = t('premium')
    const [premiumViewed, setPremiumViewed] = useState<ProgressCalcResponse[]>([{ sumOfViewed: 0, sumOfItems: 0 }])
    const getCoursesViewed = useCallback(async() => {
        if(!user?.currentUser?.uid) return
        const res = await getUnitUserDataToDatabase(user?.currentUser?.uid, "premium")
        setPremiumViewed(calculateCoursesProgressItem(res))
    }, [])
    const choose_your = t('choose_your')

    useEffect(() => {
        if(!user?.currentUser?.uid) return
        getCoursesViewed()
    }, [user])
    const handleRedirect = (route: string) => {
        navigate(route)
    }
    const [list, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
        window.scrollTo(0, 0)
    }, [user])
    return(
        <div className="section-wrapper app-wrapper column">
            <div className="section-wrapper__header typography-40  font-bebas typography-bold mt-32">
                {choose_your}&nbsp;<span className="font-bebas">{premiumTitle}</span>
            </div>
            <div className="section-wrapper__content column align-center mt-32">
                {PREMIUM.map((i, idx) =>        <div
                    className={cn("section-wrapper-item__content__border", {active: !!premiumViewed[idx]?.sumOfViewed})}>
                    {!premiumViewed[idx]?.sumOfViewed && <div className="start-btn typography-400 font-bebas" onClick={() => handleRedirect(PREMIUM_ROUTE + '/' + PREMIUM_NAMES_ROUTES[idx])}>
                        {t("start")}
                    </div>}
                    <Item
                    handleRedirect={() => handleRedirect(PREMIUM_ROUTE + '/' + PREMIUM_NAMES_ROUTES[idx])}
                    img={i.img}
                    id={i.id}
                    onSuccess={getFavorites}
                    active={!!list[i.id]}
                    route={i.url}
                    key={'premium' + idx}
                    value={"premium_info"}
                    index={idx}
                    viewed={premiumViewed[idx] || premiumViewed[0]}
                    info={premiumInfo[idx]} />     </div>)}
                </div>
        </div>
    )
}
