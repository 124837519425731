import './AgeCheck.scss';
import {useTranslation} from "react-i18next";
import {AGE_GAPS} from "../../../constants/onBoardingConstants/ageCheck.constants";
import {ButtonThemes} from "../../../constants/button.constants";
import {ContentContainer} from "../ContentContainer/ContentContainer";
import {NextRouteButton} from "../../NextRouteButton/NextRouteButton";

export const AgeCheck =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return <ContentContainer>
        <h4>{t("login")}</h4>
        <p className={'ageCheckInfo font-bebas'}>{t("ageCheckScreen.ageCheckInfo")}</p>
        <div className={'ageGapContainer'}>
            {AGE_GAPS.map((gap)=>{
                return <div key={gap} className={'ageGapButton'}>
                    <NextRouteButton option={gap} route={nextRoute}
                                     buttonTheme={ButtonThemes.DARK} className={''} />
                </div>
            })}
        </div>
    </ContentContainer>
}
