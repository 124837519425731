import {useTranslation} from "react-i18next";
import {images1} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("female_erogenous_zones_title")
    const article = t("female_erogenous_zones_article")
    const article3 = t("course2_phrases_for_messaging_article3")
    const article2 = t("female_erogenous_zones_article2")
    const list = t("female_erogenous_zones_list1", { returnObjects: true })
    const list1 = t("course2_phrases_for_messaging_list2", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[0]} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro