import {useTranslation} from "react-i18next";
import {images} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const list = t("secret_techniques_list", { returnObjects: true })
    const list1 = t("secret_techniques_list1", { returnObjects: true })
    return(
        <div className="child-container">
            {list.map((i: { title: string, description: string }, idx: number) => {
                return <>
                    <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                        {i.title}
                    </div>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i.description}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16 typography-400">
                {t("secret_techniques_article")}
            </div>
            {list1.map((i: { title: string, description: string }, idx: number) => {
                return <>
                    <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                        {i.title}
                    </div>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i.description}
                    </div>
                </>
            })
            }
        </div>
    )
}

export default Intro