import {useTranslation} from "react-i18next";

const Intro = () => {
    const { t } = useTranslation('app');
    const subtitle2 = t("techniques_title1")
    const subtitle3 = t("techniques_title2")
    const subtitle4 = t("techniques_title3")
    const subtitle5 = t("techniques_title4")
    const article1 = t("techniques_article2")
    const article2 = t("techniques_article3")
    const article3 = t("techniques_article4")
    const article4 = t("techniques_article5")
    return(
        <div className="child-container">
            <div className="child-container__article-title typography-16">
                {t("techniques_article1")}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {subtitle2}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>

            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {subtitle3}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article2}
            </div>

            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article3}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {subtitle5}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article4}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title5")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article6")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title6")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article7")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title7")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article8")}
            </div>


            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title8")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article9")}
            </div>



        </div>
    )
}

export default Intro