export const soundsRules = [
    {
        tab: 1,
    },
    {
        tab: 2,
    }
]

export const images1 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.5.1.png?alt=media&token=7fe35512-d9a1-43bb-b7bb-71ff195b2b2a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.5.2.png?alt=media&token=b2f15b8b-897e-4376-b137-ee49f7744d2c",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.5.3.png?alt=media&token=1efd8c79-9f55-4ba7-8978-4db469fa16a0"
]
