import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
import {images5} from "../FirstDateRule/constants";
const SecondRules = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("navigating_online_dating_subtitle2")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("navigating_online_dating_article2")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("navigating_online_dating_subtitle3")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images5[1]} alt="course-preview"/>
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("navigating_online_dating_article3")}
            </div>
        </div>
    )
}

export default SecondRules