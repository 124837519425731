export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
]

export const src1=[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.17.1.png?alt=media&token=e9038bdc-139a-410d-af87-1b3ba1831392",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.17.2.png?alt=media&token=5092328d-821b-4f5f-81f7-c3f5c0c39c01",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.17.3.png?alt=media&token=3326101c-8027-43cb-bae5-b327d8d22b69",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.17.4.png?alt=media&token=fe6d3e6c-f8bd-4933-9e73-00c90a1dbe88",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.17.5.png?alt=media&token=45b755bf-ed46-4621-a77f-dce97286489e",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.17.6.png?alt=media&token=31bec3bf-35a2-4f5b-b57c-5cec539762b4",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.17.7.png?alt=media&token=503ea58d-5d1d-491f-8b7b-a04d926e03b9",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.17.8.png?alt=media&token=777437bc-ff63-448b-8063-664a4f7c40db",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.17.9.png?alt=media&token=f53796d6-4531-4b25-8e72-7dfafa4d1189"
]

