import {useTranslation} from "react-i18next";
import {images1} from "./constants";

const Labia = () => {
    const { t } = useTranslation('app');
    const subtitle = t("understanding_libido_subtitle4")
    const list = t("understanding_libido_list4", { returnObjects: true })
    return (
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[4]} alt="course-preview" />
            </div>
        </div>
    )
}

export default Labia