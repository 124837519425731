import {useTranslation} from "react-i18next";
import {images2} from "./constants";

const Correctly = () => {
    const { t } = useTranslation('app');
    const list = t("her_orgasm_list2", { returnObjects: true })
    return (
        <div className="child-container">
            {list.map((i: { title: string, description: string }, idx: number) => {
                return <>
                    <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                        {i.title}
                    </div>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i.description}
                    </div>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={images2[idx]} alt="course-preview" />
                    </div>
                </>
            })
            }

        </div>
    )
}

export default Correctly