import './PointsWillLearnContainer.scss';
import {
    arousingSMS, awakenSexuality, boostingLibido, dateIdeas, howToEngage,
    methodsOfConnection, sexScenarios,
    signsOfMan,
    socialMediaMan,
    waysToMeetMan
} from "../../../assets/onBoardingImages/whatWillLearnImages";
import {TFunction} from "i18next";

export const PointsWillLearnContainer =({t}: { t: TFunction })=>{
        const pointsWillLearnData=[
        {imageSrc:waysToMeetMan,text:t("payInfoScreen.willLearnLeftColumnImages.firstImageText")},
        {imageSrc:methodsOfConnection,text:t("payInfoScreen.willLearnRightColumnImages.firstImageText")},
        {imageSrc:signsOfMan,text:t("payInfoScreen.willLearnLeftColumnImages.secondImageText")},
        {imageSrc:socialMediaMan,text:t("payInfoScreen.willLearnRightColumnImages.secondImageText")},
        {imageSrc:arousingSMS,text:t("payInfoScreen.willLearnLeftColumnImages.thirdImageText")},
        {imageSrc:howToEngage,text:t("payInfoScreen.willLearnRightColumnImages.thirdImageText")},
        {imageSrc:dateIdeas,text:t("payInfoScreen.willLearnLeftColumnImages.fourthImageText")},
        {imageSrc:boostingLibido,text:t("payInfoScreen.willLearnRightColumnImages.fourthImageText")},
        {imageSrc:awakenSexuality,text:t("payInfoScreen.willLearnLeftColumnImages.fifthImageText")},
        // {imageSrc:sexScenarios,text:t("payInfoScreen.willLearnRightColumnImages.fifthImageText")},
    ]

    return (
            <div className={'pointsWillLearnContainer'}>
                <h4 className={'tryAppTitle font-bebas'}>{t("payInfoScreen.tryManMasteryText")}</h4>
                {/*<h2 className={'tryTerm'}>{t("payInfoScreen.tryTerm").toUpperCase()}</h2>*/}
                <h4 className={'willLearnTitle font-bebas'}>{t("payInfoScreen.willLearnText")}</h4>
                <div className={'pointsWillLearn'}>
                    {pointsWillLearnData.map(({imageSrc,text})=>{
                        return <div key={text} className={'pointWillLearn'}>
                            <img loading="lazy" src={imageSrc} alt={''}/>
                            <h6 className="font-bebas">{text}</h6>
                        </div>
                    })}
                </div>
            </div>
    )
}
