import {useTranslation} from "react-i18next";
const Place = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("pathways_subtitle4")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("pathways_article4")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("pathways_subtitle5")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("pathways_article5")}
            </div>
        </div>
    )
}
export default Place