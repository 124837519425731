import {ReactComponent as CourseIcon} from "assets/app/Footer/course.svg";
import {ReactComponent as GameIcon} from "assets/app/Footer/game.svg";
import {ReactComponent as ScenarioIcon} from "assets/app/Footer/scenario.svg";
import {ReactComponent as PremiumIcon} from "assets/app/Footer/premium.svg";
import {ReactComponent as ProgressIcon} from "assets/app/Footer/progress.svg";
import {useLocation, useNavigate} from "react-router";
import {
    COURSES,
    GAMES_ROUTE,
    PREMIUM_ROUTE,
    PROGRESS_ROUTE,
    SCENARIOS_ROUTE
} from "../../../constants/routes.constants";
import cn from "classnames";
import './style.scss'
import {useTranslation} from "react-i18next";

export const Footer = () => {
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const {t} = useTranslation('app');
    return(
        <div className="footer-container">
            <div className="footer-container__wrapper row-container">
            <div className={cn("footer-container__icon column", {active: pathname.includes('courses')})} onClick={() => navigate(COURSES)}>
                <CourseIcon />
                <div className={cn("footer-container__icon__label typography-12 typography-500 font-bebas")}>
                    {t("course")}
                </div>
            </div>
            <div className={cn("footer-container__icon column", {active: pathname.includes('games')})} onClick={() => navigate(GAMES_ROUTE)}>
                <GameIcon />
                <div className={cn("footer-container__icon__label typography-12 typography-500 font-bebas")}>
                    {t("games")}
                </div>
            </div>
            <div className={cn("footer-container__icon column", {active: pathname.includes('scenarios')})} onClick={() => navigate(SCENARIOS_ROUTE)}>
                <ScenarioIcon />
                <div className={cn("footer-container__icon__label typography-12 typography-500 font-bebas")}>
                    {t("scenarios")}
                </div>
            </div>
            <div className={cn("footer-container__icon column", {active: pathname.includes('progress')})} onClick={() => navigate(PROGRESS_ROUTE)}>
                <ProgressIcon />
                <div className={cn("footer-container__icon__label typography-12 typography-500 font-bebas")}>
                    {t("progress")}
                </div>
            </div>
            <div className={cn("footer-container__icon column", {active: pathname.includes('premium')})} onClick={() => navigate(PREMIUM_ROUTE)}>
                <PremiumIcon />
                <div className={cn("footer-container__icon__label typography-12 typography-500 font-bebas")}>
                    {t("premium")}
                </div>
            </div>
            </div>
        </div>
    )
}