export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
]

export const src1=[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.19.1.png?alt=media&token=bf64f0a1-5aab-4f49-aeeb-2bc8d60263ce",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.19.2.png?alt=media&token=d5b86faa-f169-4bab-b0a1-3d56b1790bec",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.19.3.png?alt=media&token=1daeca5c-61b0-4ba5-a39b-452a1e314b76"
]