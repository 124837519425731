import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './BoredomDiscussionScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {candorCheck1} from "../../../assets/onBoardingImages";
export const BoredomDiscussionScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={candorCheck1} />
                <h3 className={'boredomDiscussionTitle font-bebas'}>{t("boredomDiscussionScreen.title")}</h3>
                <p className={'boredomDiscussionText'}>{t("boredomDiscussionScreen.text")}</p>
                <h6 className={'boredomDiscussionQuestion font-bebas'}>{t("boredomDiscussionScreen.discussionResult")}</h6>
            </ContentContainer>
            <NextRouteButton option={t("boredomDiscussionScreen.agreeButtonText")}
                             route={nextRoute}
                             buttonTheme={ButtonThemes.DARK}
                             className={'boredomDiscussionAgreeButton'} />
        </>
    )
}
