import React, {useCallback, useContext, useEffect, useState} from 'react';
import Modal from 'react-modal';
import {ReactComponent as CloseIcon} from "assets/common/cross.svg";
import {useTranslation} from "react-i18next";
import {Input} from "../../lego/Input/Input";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";
import Select from 'react-select';
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";
import {ButtonThemes} from "../../../constants/button.constants";
import {Button} from "../../lego/Button/Button";
import {PAYMENT_API, TOKEN_PAYMENT, URL_CITY, URL_COUNTRIES, URL_STATE} from "./constants";
import {PayCardsContainer} from "./PayCardsContainer/PayCardsContainer";
import {ThemeContext} from "../../../context/themeContext";
import {isEmailValid, isEmptyInput} from "../../../utils/login.utils";
import {FormErrorMessages} from "../../../constants/logInErrorMessages.constants";
import {TFunction} from "i18next";
import CardInfo from './CardInfo';

const generateUniqueString = ()  => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueString = '';

    for (let i = 0; i < 10; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        uniqueString += characters.charAt(randomIndex);
    }

    return uniqueString;
}

export type TProps = {
    open: boolean;
    setOpen: (val: boolean) => void;
    price: number;
    isTimerOver: boolean;
    t: TFunction;
    setIsClosedFirsTime: (val: boolean) => void;
    setIsNewPrice: (val: boolean) => void;
    isClosedFirsTime: boolean
    isNewPrice: boolean
}

export type TSelectValue = {
    value: string;
    label: string
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const FIRST_NAME_ID ='first_name';
const LAST_NAME_ID ='last_name';
const ZIP_ID ='zp';
const EMAIL_ID='email'
export const PaymentModal = ({open, setOpen, price, isTimerOver, setIsClosedFirsTime, isClosedFirsTime, isNewPrice, setIsNewPrice}: TProps) => {
    const { theme } = useContext(ThemeContext);

    const {t} = useTranslation('app')
    const onboarding= useTranslation('onboarding')
    const [isCardNumberForm, setIsCardNumberForm] = useState(false)
    const [first_name, setFirstName]=useState('');
    const [last_name, setLastName]=useState('');
    const [countryList, setCountryList]=useState([]);
    const [stateList, setStateList]=useState([]);
    const [cityList, setCityList]=useState([]);
    const [email,setEmail]=useState('')
    const [selectedCountry, setSelectedCountry] = useState<{value: string; label: string;} | null>(null)
    const [selectedState, setSelectedState] = useState<TSelectValue | null>(null)
    const [selectedCity, setSelectedCity] = useState<TSelectValue | null>(null)
    const [zip, setZip]=useState('');
    const {errorState, setErrorState, clearErrorMessage}=useLogInErrorLogic()
        useEffect(() => {
            if(open){
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'unset';
            }
        }, [open])

    const clearErrorData=(value:string, setInputValue: (inputValue:string)=>void)=>{
        clearErrorMessage()
        setInputValue(value)
    }


    const getCountryList = useCallback(async () => {
        const res = await fetch(URL_COUNTRIES, {method: "GET"})
            .then((response) => response.json())
        if(!!res?.data?.length) setCountryList(res.data?.slice(46)?.map((i:{country: string, code: number}) => ({label: i?.country, value: i?.code})))
    },[])

    const getStateList = useCallback(async () => {
        if(!selectedCountry) return
        const res = await fetch(URL_STATE(selectedCountry.label), {method: "GET"}).then((response) => response.json())
        if(!!res?.data?.states?.length)setStateList(res.data.states.map((i:{name: string, state_code: number}) => ({label: i.name, value: i.state_code})))
    },[selectedCountry])
    const getCityList = useCallback(async () => {
        if( !selectedCountry) return
        const res = await fetch(URL_CITY(selectedState?.label || '', selectedCountry.label), {method: "GET"}).then((response) => response.json())
        setCityList(res.data.map((i:string) => ({label: i, value: i})))
    },[selectedState, selectedCountry])

    useEffect(() => {
        getCountryList()
    }, [])
    useEffect(() => {
        if(selectedCountry?.value)
            getStateList()
    }, [selectedCountry])
    useEffect(() => {
        if(selectedState?.value)
            getCityList()
    }, [selectedState, selectedCountry])

    const onSubmit  = async() => {
        if(!isCardNumberForm){
            return  setIsCardNumberForm(true)
        }
        setIsNewPrice(false)
        setIsCardNumberForm(false)

        return setOpen(false)

        if(!isEmptyInput(first_name)) {
            setErrorState({message:onboarding.t(FormErrorMessages.EMPTY_USERNAME),errorInputId:FIRST_NAME_ID})
            return;
        }

        if(!isEmptyInput(last_name)) {
            setErrorState({message:onboarding.t(FormErrorMessages.EMPTY_USERNAME),errorInputId:LAST_NAME_ID})
            return;
        }
        if(!isEmptyInput(email)) {
            setErrorState({message:onboarding.t(FormErrorMessages.EMPTY_EMAIL),errorInputId:EMAIL_ID})
            return;
        }
        if(!selectedCountry?.value) {
            return;
        }
        if(!selectedState?.value) {
            return;
        }
        if(!selectedCity?.value) {
            return;
        }

        if(!isEmailValid(email)) {
            setErrorState({message:onboarding.t(FormErrorMessages.NOT_VALID_EMAIL),errorInputId:EMAIL_ID})
            return;
        }
        if(!isEmptyInput(zip)) {
            return;
        }

        const date =        new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' +     new Date().getDate()
        if(!selectedState || !selectedCountry) return
        // const data = {
        //     "firstName": first_name,
        //     "middleName": "",
        //     "lastName": last_name,
        //     "reference": generateUniqueString(),
        //     "email": email,
        //     "dob": date,
        //     "contactNumber": "+12345678",
        //     "address": selectedCountry?.label,
        //     "country": selectedCountry?.label,
        //     "state": selectedState?.label,
        //     "city": selectedCity?.label || 'n/a',
        //     "zipCode": zip,
        //     "currency": "USD",
        //     "amount": price,
        //     "ttl": 5,
        //     "tagName": "USD",
        //     "productUrl": "",
        //     "redirectUrl": "",
        //     "merchantAaccountId": 100074,
        //     "webhookUrl" :  "https://webhook.site/327a4935-4fc5-4bf2-a5dd-c164958bc9e6"
        // }
        // const res = await fetch(PAYMENT_API, {method: "POST", headers: {
        //         "Authorization" : TOKEN_PAYMENT,
        //         "Content-Type": "application/json"
        //     }, body: JSON.stringify(data)}).then((response) => {
        //         debugger
        //        return response.json()
        // })
        // if(!res.data?.payment_url) return
        // window.open(res.data.payment_url)
    }

    return(
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            // style={customStyles}
            contentLabel="Example Modal"
            preventScroll={true}
            className={"payment-modal" + ' ' + theme}
        >
            <div className="payment-modal-content">
                <div className="payment-modal-content__header-icon">
                    <CloseIcon onClick={() => {
                        setIsNewPrice(false)
                        setIsCardNumberForm(false)
                        if(!isClosedFirsTime){
                            setIsClosedFirsTime(true)
                        } else {
                            setOpen(false)
                        }
                    }} />
                </div>
                <div className="payment-modal-content__header-title">
                    <div className="typography-28">
                        {t("payment_details")}
                    </div>
                </div>
                    <div className={'paymentForm'}>
                        {!isCardNumberForm ?  <>
                        <Input onChange={(value)=>clearErrorData(value,setFirstName)}
                               id={FIRST_NAME_ID}
                               inputType={'first_name'}
                               placeholderText={t("first_name")}
                               errorMessage={errorState.errorInputId===FIRST_NAME_ID?errorState.message:''}
                        />
                        <Input onChange={(value)=>clearErrorData(value,setLastName)}
                               id={LAST_NAME_ID}
                               inputType={'last_name'}
                               placeholderText={t("last_name")}
                               errorMessage={errorState.errorInputId===LAST_NAME_ID?errorState.message:''}
                        />
                        <Input onChange={(value)=>clearErrorData(value,setEmail)}
                               id={EMAIL_ID}
                               inputType={'email'}
                               placeholderText={onboarding.t("createAccountScreen.email.placeholder")}
                               errorMessage={errorState.errorInputId===EMAIL_ID?errorState.message:''}
                        />
                        <Select
                            onChange={setSelectedCountry}
                            options={countryList}
                            value={selectedCountry}
                            className={'basic-select'}
                            placeholder={"Country"}
                            classNamePrefix="select"
                        />
                        <div className="two-in-the-row">
                            <Select
                                onChange={setSelectedState}
                                options={stateList}
                                placeholder={"State"}
                                value={selectedState}
                                className={'basic-select'}
                                classNamePrefix="select"
                            />
                            <Select
                                onChange={setSelectedCity}
                                options={cityList}
                                value={selectedCity}
                                placeholder={"City"}
                                className={'basic-select'}
                                classNamePrefix="select"
                            />
                            <Input onChange={(value)=>clearErrorData(value,setZip)}
                                   id={ZIP_ID}
                                   inputType={'zip'}
                                   placeholderText={"Zip"}
                                   errorMessage={errorState.errorInputId===ZIP_ID?errorState.message:''}
                            />
                        </div>
                        </> : <CardInfo />}
                        <div className="payment-modal-content__footer-icon">
                            <PayCardsContainer />
                        </div>
                        <div className="payment-modal-content__footer-weekly column">
                            <div className="row-container space-between align-center">
                                <div className="secondary-payment-text typography-16">{onboarding.t("payInfoScreen.weeklyPlan")}</div>
                                <div className="total-price-wrapper typography-16"> ${price}</div>

                            </div>
                            <div className="row-container space-between align-center mt-12">
                                <div className="secondary-payment-text typography-16"> {onboarding.t("payInfoScreen.limitedTime")?.replace('50%', isNewPrice ? '60%' : '50%')}</div>
                                <div className="total-price-wrapper typography-16"> -${(isTimerOver ? price : price * (isNewPrice ? 0.6 : 0.5)).toFixed(2)}</div>
                            </div>
                        </div>
                        <div className="payment-modal-content__total">
                            <div className="payment-modal-content__total__name typography-32">{t("totalTitle")}</div>
                            <div className="column align-end">
                            <div className="payment-modal-content__total__price typography-24 typography-bold">${((isTimerOver ? price : price * (isNewPrice ? 0.4 : 0.5)) + 5).toFixed(2)}</div>
                            <div className="payment-modal-content__total__save typography-14 mt-12">{(isTimerOver ? price : price * (isNewPrice ? 0.4 : 0.5)).toFixed(2)} + $5 VAT</div>
                            <div className="red-color typography-14 mt-12">{onboarding.t("payInfoScreen.youSaved")?.replace('50%', isNewPrice ? '60%' : '50%')} ${(isTimerOver ? price : price * (isNewPrice ? 0.4 : 0.5)).toFixed(2)} ({isNewPrice ? '60% ' : '50% '} off)</div>
                            </div>
                        </div>

                        <Button className="" onClick={onSubmit} text={onboarding.t("testBeginningScreen.continueButton")}
                                buttonTheme={ButtonThemes.DARK} width={100}
                        />
                        <div className="payment-modal-content__footer-text mt-24">
                            {/*<div>{t("paymentFirstText")}</div>*/}
                            <div>{onboarding.t("payInfoScreen.footerText")}</div>
                            <div className="row-container justify-center text-align-center">G4Capital OÜ Harju maakond, Tallinn, Kesklinna linnaosa, Narva mnt 13, 10151</div>
                            <div>{`${onboarding.t("payInfoScreen.moneyBack.learnMoreText")} `}
                               &nbsp; <a href={PolicyAnchors.SUBSCRIPTION}>{onboarding.t("payInfoScreen.subscriptionTermsAnchor")}</a>
                            </div>
                        </div>

                    </div>
            </div>

        </Modal>
    )
}
