export const imgUrl = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.1.png?alt=media&token=a7b1c19e-ee04-458d-bd07-eb929e34784f",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.2.png?alt=media&token=6e41e383-8d26-4909-933c-13dc938bf266",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.3.png?alt=media&token=308a13c9-fa49-4689-9892-966044d9b2d2",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.4.png?alt=media&token=a28c59e5-bfae-4a18-b2e1-66bf7c531100",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.5.png?alt=media&token=5eb1e246-ba3d-464b-bada-8db7fc62653f",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.6.png?alt=media&token=2e10f2a9-5e77-4642-ac7b-b8dfb9be0886",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.7.png?alt=media&token=3e43638c-6adb-438a-9091-16c29180afff",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.8.png?alt=media&token=20e9c670-d688-41fe-90be-97a5a292d7c7",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.9.png?alt=media&token=9424c413-3b14-49f0-b39a-5794cfdf2ce0",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.10.png?alt=media&token=56caf71f-694e-4061-99b9-6fce6031ec6c",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.11.png?alt=media&token=413c9cac-b13d-4440-b03d-9f55c94fef9a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.12.png?alt=media&token=f90c2da4-1dd1-468e-b31a-f82238b044c6",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.13.png?alt=media&token=54c1d0ee-fdc1-495d-8430-9303b0bcb9eb",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMature%2F6.1.14.png?alt=media&token=8e539ab8-b00c-4a4f-aca0-a4f8ddd3e159",

]






