export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FBonding%2F5.2.1.png?alt=media&token=9e72cd8d-5aeb-4979-8297-9e581004f16b",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FBonding%2F5.2.2.png?alt=media&token=ad3c44df-5e0d-48fd-8841-ce558dbfc521",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FBonding%2F5.2.3.png?alt=media&token=3f34b15e-c54d-4e91-81ae-bba249fa4cd9",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FBonding%2F5.2.4.png?alt=media&token=e276e3f3-f7a0-42d2-b201-0700bffe8147",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FBonding%2F5.2.5.png?alt=media&token=c8c8a8fd-2e58-4e26-87bf-e0716edb6d9e"
  ]



