import {useTranslation} from "react-i18next";
import {tryToBeOpen} from "../../../assets/onBoardingImages";
import {ButtonThemes} from "../../../constants/button.constants";
import './DistantRelationshipScreen.scss';
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
export const DistantRelationshipScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={tryToBeOpen} />
                <h3 className={'distantRelationshipTitle'}>{t("distantRelationshipScreen.title")}</h3>
                <p className={'distantRelationshipText'}>{t("distantRelationshipScreen.text")}</p>
            </ContentContainer>
            <NextRouteButton option={t("distantRelationshipScreen.tryButtonText")}
                             route={nextRoute}
                             buttonTheme={ButtonThemes.DARK}
                             className={'distantRelationshipButton'} />
        </>
    )
}
