import {useTranslation} from "react-i18next";

const HowToGetAGuy = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("navigating_online_dating_subtitle6")}
            </div>
            <div className="child-container__list">
                {t("navigating_online_dating_list6_1", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__list">
                <ul>
                    {t("navigating_online_dating_list6_2", {returnObjects: true}).map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
        </div>
    )
}

export default HowToGetAGuy