import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {DAYS_MONTHLY} from "../../contants";

export const Monthly = () => {
    const { t } = useTranslation('app');
   const titleHeader = t("days_monthly_premium")?.split(' ').slice(0, 3).join(' ').replace("\"", "")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleHeader}
                        isActive={!!listFav[("days_monthly") + 0]}
                        objectToSave={{
                            img: DAYS_MONTHLY[0].img,
                            value: 'days_monthly',
                            route: '/monthly-challenges',
                            index: 0
                        }}
                        uuid={"days_monthly0"}
                        route={"/premium/days-monthly"}
            />
        <div className="child-container">
            <div className="child-container__article-title typography-16">
                {t("premium_monthly_article")}
            </div>
            <div className="child-container__red-title font-bebas typography-18 mt-24 typography-bold">
                {t("premium_monthly_title")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            {t("premium_monthly_list", {returnObjects: true}).map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {t("premium_monthly_title1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            {t("premium_monthly_list1", {returnObjects: true}).map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {t("premium_monthly_title2")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            {t("premium_monthly_list2", {returnObjects: true}).map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {t("premium_monthly_title3")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[3]} alt="course-preview" />
            </div>
            {t("premium_monthly_list3", {returnObjects: true}).map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {t("premium_monthly_title4")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[4]} alt="course-preview" />
            </div>
            {t("premium_monthly_list4", {returnObjects: true}).map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {t("premium_monthly_title5")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[5]} alt="course-preview" />
            </div>
            {t("premium_monthly_list5", {returnObjects: true}).map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }

                 </div>
            </>
            )
}