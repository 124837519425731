export const hundredsOfThousands = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%203.png?alt=media&token=93c3aef7-b456-4b48-8a64-b7c8f2a1df05';
export const feelPhysicallyConfident = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%206.png?alt=media&token=e9d55f37-166c-4cf9-bd1e-da89a399587e';
export const currentRelationship = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%208.png?alt=media&token=a127189d-dc37-4be5-a420-c8aa2d356f72';
export const candorCheck = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%2010%20.png?alt=media&token=0cf92faf-483e-4473-8f55-4dda29c3ee45';
export const candorCheck1 = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%208.png?alt=media&token=a127189d-dc37-4be5-a420-c8aa2d356f72';
export const reallyClever = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%2010%20.png?alt=media&token=0cf92faf-483e-4473-8f55-4dda29c3ee45';
export const tryToBeOpen = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%2011%20.png?alt=media&token=eb512e7b-9c8d-4b4b-b2b7-3cd25647598e';
export const partnerExplorePicture = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%2013.png?alt=media&token=efc46668-58f9-4a5b-a8c4-03ff9ae78a97';
export const sexualPotentialPicture = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%2014%20%20.png?alt=media&token=cf21af05-3865-48bb-a04a-8a55d3d57a7a';
export const beEnticingOne = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%2015%20.png?alt=media&token=b68d3511-82dd-4f6e-a1fa-5ac7cb365b4f';
export const goodTestResult = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%2018.png?alt=media&token=0c1581a5-f10a-4f65-ae3d-22926f788ea4';
export const averageTestResult = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%2017.png?alt=media&token=304f34fb-e694-4421-9b4d-35741ce09fff';
export const unsatisfactoryTestResult = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FScreen%2018.png?alt=media&token=0c1581a5-f10a-4f65-ae3d-22926f788ea4';
export const newLifeJourney='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FYour%20journey%20into%20your%20new%20life%20starts%20here.png?alt=media&token=e6d0e1d4-2c2e-441b-b244-0ad05d78b55d'
export const learnHowEasyLogo='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FText%20and%20Image%20-%20Learn%20how%20easy%20it%20is%20to.png?alt=media&token=a85c797b-aa78-4dbc-a3ce-401e471367af'
export const moneyBackGuarantee='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FImage%20-%20Money-Back%20Guarantee.png?alt=media&token=ff6c280e-96b4-4d8b-92d0-45107708b716'
export const moneyBackGuaranteeLogo='https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FImage%20-%20Money-Back%20Guarantee.png?alt=media&token=ff6c280e-96b4-4d8b-92d0-45107708b716'