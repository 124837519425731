import './AppPreview.scss';
import {TFunction} from "i18next";
import {
    fifthDarkThemeAppMockup,
    fourthDarkThemeAppMockup,
    thirdDarkThemeAppMockup,
    secondDarkThemeAppMockup,
    firstDarkThemeAppMockup,
    sixDarkThemeAppMockup,
    sevenDarkThemeAppMockup,
    nineDarkThemeAppMockup,
    firstLightThemeAppMockup,
    secondLightThemeAppMockup,
    thirdLightThemeAppMockup,
    fourthLightThemeAppMockup,
    fifthLightThemeAppMockup,
    sixLightThemeAppMockup,
    sevenLightThemeAppMockup,
    eightLightThemeAppMockup, nineLightThemeAppMockup
} from "../../../assets/onBoardingImages/mobileMockups";
import {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../../context/themeContext";
import {AppThemes} from "../../../constants/theme.constants";
import {CarouselComponent} from "../../Carousel/Carousel";
import {useAppContainerWidth} from "../../../hooks/onboarding/useAppContainerWidth";
import {eightDarkThemeAppMockup} from "../../../assets/onBoardingImages/mobileMockups";

const appLightThemedMockups=[
    firstLightThemeAppMockup,
    secondLightThemeAppMockup,
    thirdLightThemeAppMockup,
    fourthLightThemeAppMockup,
    fifthLightThemeAppMockup,
    sixLightThemeAppMockup,
    sevenLightThemeAppMockup,
    eightLightThemeAppMockup,
    nineLightThemeAppMockup
]

const appDarkThemedMockups=[
    firstDarkThemeAppMockup,
    secondDarkThemeAppMockup,
    thirdDarkThemeAppMockup,
    fourthDarkThemeAppMockup,
    fifthDarkThemeAppMockup,
    sixDarkThemeAppMockup,
    sevenDarkThemeAppMockup,
    eightDarkThemeAppMockup,
    nineDarkThemeAppMockup
]

export const AppPreview =({t}: { t: TFunction })=>{
    const { theme } = useContext(ThemeContext);
    const appContainerWidth=useAppContainerWidth()
    const [mobileMockups,setMobileMockups]=useState<string[]>(appDarkThemedMockups)


    useEffect(()=>{
        setMobileMockups(theme===AppThemes.DARK ? appDarkThemedMockups : appLightThemedMockups)
    },[theme])

    return (
        <div className={'appPreviewContainer'} style={{maxWidth:!!appContainerWidth ? appContainerWidth : '100%', width:!!appContainerWidth ? appContainerWidth : '100%'}}>
            <h4 className={'appPreviewTitle'}>{t("payInfoScreen.mobileDeviceAccessText")}:</h4>
            <CarouselComponent itemPadding={100}>
                {mobileMockups.map((mockupImg)=>{
                       return <img loading="lazy" key={mockupImg} className={'appImage'} src={mockupImg} alt={''}/>
                })}
            </CarouselComponent>
        </div>
    )
}