import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
const Scheme = () => {
    const { t } = useTranslation('app');
    const title = t("approaches_title")
    const subText = t("approaches_article1")
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="mt-20 typography-14">
                {subText}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("approaches_note1")}
                </div>
            </div>
        </div>
    )
}

export default Scheme