import {useTranslation} from "react-i18next";
import {images1} from "./constants";

const Position = () => {
    const { t } = useTranslation('app');
    const subtitle = t("female_erogenous_zones_subtitle2")
    const article = t("female_erogenous_zones_subtitle3")
    const list = t("female_erogenous_zones_list2", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__list">
                {t("female_erogenous_zones_list3", {returnObjects: true}).map((i: string, idx: number) => <div
                    key={idx}
                    className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[1]} alt="course-preview" />
            </div>
        </div>
    )
}

export default Position