import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
import {images4} from "../FirstDateRule/constants";
const Phrases = () => {
    const { t } = useTranslation('app');
    const list = t("approaches_list1", { returnObjects: true })
    return(
        <div className="child-container">
            {list.map((i:{title: string, description: string}, idx: number) =>  {
                return <><div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                    {i?.title}
                </div>
                    {idx < 5 && <div className="child-container__image mt-24">
                        <img loading="lazy" src={images4[idx]} alt="course-preview"/>
                    </div>}
                    <div className="child-container__list">
                        <div className="mt-20 typography-14">
                            {i.description}
                        </div>
                    </div>
                </>})}
        </div>
    )
}

export default Phrases