export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
]

export const src1=[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.22.1.png?alt=media&token=71b634c1-6315-4cf0-abc0-af42f465ac7c",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.22.2.png?alt=media&token=f3a1de9b-2c53-45f0-8342-9d9f2be9f813",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.22.3.png?alt=media&token=1a2ad1f0-8c2c-4217-95db-6ef324f148ad",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.22.4.png?alt=media&token=2b8efee0-7db2-4812-b890-89ebad7b64d2",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.22.5.png?alt=media&token=579311c5-1dff-4d96-83ea-d8f364545c28",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.22.6.png?alt=media&token=8fecdb9a-f573-4462-b0b0-29edad92a090",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.22.7.png?alt=media&token=357f992d-c8ee-4292-b4d6-c44d2099359a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.22.8.png?alt=media&token=447d5051-5b05-4e8c-9f50-92b488cfc2d8"
]
