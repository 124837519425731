import {useTranslation} from "react-i18next";

const Signs = () => {
    const { t } = useTranslation('app');
    const title = t("reading_body_language_title")
    const subText = t("reading_body_language_article")
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="mt-20 typography-14">
                {subText}
            </div>
        </div>
    )
}

export default Signs