import {useParams} from "react-router";
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import '../../FirstDateRule/styles.scss'
import {libidoRoutes} from "./constants";
import Increase from "./Increase";
import Intro from "./Intro";
import ThreeFingers from "./ThreeFingers";
import Vibration from "./Vibration";
import Labia from "./Labia";
import SlipperyLips from "./SlipperyLips";
import Stroking from "./Stroking";
import Squirt from "./Squirt";
import Uterine from "./Uterine";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PLEASURE_PATHWAYS} from "../../../CourseItem/contants";

const Libido = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("pleasure_pathways_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("pleasure_pathways") + 3]}
                        objectToSave={{
                            img: PLEASURE_PATHWAYS[3].img,
                            value: 'pleasure_pathways',
                            route: '/libido/1',
                            index: 3
                        }}
                        uuid={"pleasure_pathways3"}
                        route={"/courses/pleasure-pathways"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Increase />} />
                <Route path={'/3'} element={<Vibration />} />
                <Route path={'/4'} element={<ThreeFingers />} />
                <Route path={'/5'} element={<Labia />} />
                <Route path={'/6'} element={<SlipperyLips />} />
                <Route path={'/7'} element={<Stroking />} />
                <Route path={'/8'} element={<Squirt />} />
                <Route path={'/9'} element={<Uterine />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={libidoRoutes} routeToNavigate={COURSE_ROUTES.LIBIDO}  />
        </>
    )
}

export default Libido