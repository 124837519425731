import {useTranslation} from "react-i18next";
import {images1} from "./constants";

const VaginalSounds = () =>{
    const { t } = useTranslation('app');
    const list = t("being_the_rock_list1", { returnObjects: true })
    const list1 = t("being_the_rock_list2", { returnObjects: true })
    const list2 = t("being_the_rock_list3", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__list">
                {list.map((i: string, idx: number) => <div key={idx} className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>

            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[1]} alt="course-preview" />
            </div>

            <div className="child-container__list">
                {list1.map((i: string, idx: number) => <div key={idx} className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[2]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list2.map((i: string, idx: number) => <div key={idx} className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>

        </div>
    )
}

export default VaginalSounds