export const firstDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FMockups%2FX%20Mockup1.png?alt=media&token=008aee46-bca3-46af-a0a8-cfcc3d701b40'
export const secondDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FMockups%2FX%20Mockup2.png?alt=media&token=d1033e6e-4d21-487a-8dca-b7bb74f17432'
export const thirdDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FMockups%2FX%20Mockup3.png?alt=media&token=a1868f00-b23e-45c6-b98e-b374388cff06'
export const fourthDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FMockups%2FX%20Mockup4.png?alt=media&token=a6ea4a8b-0cb8-40a8-94cc-353f907e5dcb'
export const fifthDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FMockups%2FX%20Mockup5.png?alt=media&token=fe8ff28c-1d22-4feb-8e25-b95bb48c5356'
export const sixDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FMockups%2FX%20Mockup6.png?alt=media&token=8404c484-bcef-4fa0-9763-d50f1614f728'
export const sevenDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FMockups%2FX%20Mockup7.png?alt=media&token=de100f7a-9652-4197-9db8-c34c61aa9878'
export const eightDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FMockups%2FX%20Mockup8.png?alt=media&token=710a8fe3-5377-4dcc-9dea-32f0211bf2ac'
export const nineDarkThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Onboarding%2FMockups%2FX%20Mockup9.png?alt=media&token=b7a143f1-7fd5-4c71-8031-7fcdbeb9331b'
export const firstLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/mockups%2FX%20Mockuplight-1.png?alt=media&token=56a10b53-3eab-4718-871f-33f9a88ea0cc'
export const secondLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/mockups%2FX%20Mockuplight-2.png?alt=media&token=567464d0-7a86-4b91-b194-f5fea82686c7'
export const thirdLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/mockups%2FX%20Mockuplight-3.png?alt=media&token=d372a609-7dfd-40ea-a99f-2112648ff2e5'
export const fourthLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/mockups%2FX%20Mockuplight-4.png?alt=media&token=98cfa438-05d5-4c96-9730-e7a6908a6464'
export const fifthLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/mockups%2FX%20Mockuplight-5.png?alt=media&token=de97da88-1c30-48c8-b726-a955e859cf4d'
export const sixLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/mockups%2FX%20Mockuplight-6.png?alt=media&token=61dd582e-5c10-4e61-97a5-6fb0cd7953e5'
export const sevenLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/mockups%2FX%20Mockuplight-7.png?alt=media&token=b6c07b8e-638f-4c5f-afd7-b844b7ca2969'
export const eightLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/mockups%2FX%20Mockuplight-8.png?alt=media&token=b8478a12-7441-4054-9846-e242a99948a0'
export const nineLightThemeAppMockup = 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/mockups%2FX%20Mockuplight-9.png?alt=media&token=9d24c71b-754f-4569-a421-7828f84505e3'
