import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
import {images5} from "../FirstDateRule/constants";
const FirstRules = () => {
    const { t } = useTranslation('app');
    const title = t("navigating_online_dating_title")
    const subText = t("navigating_online_dating_article1")
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("navigating_online_dating_subtitle1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images5[0]} alt="course-preview"/>
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {subText}
            </div>
        </div>
    )
}

export default FirstRules