export const libidoRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
]

export const images1 = [
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.4.1.png?alt=media&token=7e3e7b6f-51d1-435b-aa17-7e96f3266ad9",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.4.2.png?alt=media&token=b305feb1-7eb8-4455-9db9-e432ac727ba8",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.4.3.png?alt=media&token=25bea99d-aa7a-48a4-86aa-63233c917ea7",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.4.4.png?alt=media&token=e78781e8-b8ae-4b87-9927-c2fdb816eae3",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.4.5.png?alt=media&token=f75ac288-547a-41a2-9eaa-2cd7e4b6999c",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.4.6.png?alt=media&token=230bb922-0aec-4111-bab3-3dc2d92b08c5",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.4.7.png?alt=media&token=ed78f3c4-b9f7-4547-99ab-f73e25d0048b",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.4.8.png?alt=media&token=a269b486-49b4-437c-bdcd-53968e09e78b",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.4.9.png?alt=media&token=0b39be64-312f-41b9-985c-12023de5c383"
]
