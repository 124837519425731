import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './ConfidentCheckScreen.scss';
import {feelPhysicallyConfident} from "../../../assets/onBoardingImages";
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";

export const ConfidentCheckScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    const confidentCheckAnswerOptions:string[]= [
        t("confidentCheckScreen.agreeButton"),
        t("confidentCheckScreen.disagreeButton"),
        t("confidentCheckScreen.totallyDisagreeButton"),
    ];

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={feelPhysicallyConfident} /></ContentContainer>
            <p className={'confidentCheckQuestion font-bebas'}>{t("confidentCheckScreen.confidentFeelQuestion")}</p>

            <div>
                {confidentCheckAnswerOptions.map((optionText)=>{
                    return  <NextRouteButton key={optionText} option={optionText}
                                             route={nextRoute}
                                             buttonTheme={ButtonThemes.DARK}
                                             className={'confidentCheckButton'} />
                })}
            </div>
        </>
    )
}
