import {useTranslation} from "react-i18next";
import {images} from "./constants";

const Part1 = () => {
    const { t } = useTranslation('app');
    const list = t("escaping_the_friend_zone_list", { returnObjects: true })
    return (
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("escaping_the_friend_zone_subtitle1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[0]} alt="course-preview"/>
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("escaping_the_friend_zone_subtitle2")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[1]} alt="course-preview"/>
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("escaping_the_friend_zone_article2")}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("escaping_the_friend_zone_note2")}
                </div>
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("escaping_the_friend_zone_subtitle3")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[2]} alt="course-preview"/>
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("escaping_the_friend_zone_article3")}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("escaping_the_friend_zone_note3")}
                </div>
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("escaping_the_friend_zone_subtitle4")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[3]} alt="course-preview"/>
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("escaping_the_friend_zone_article4")}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("escaping_the_friend_zone_note4")}
                </div>
            </div>
        </div>
    );
}

export default Part1