import {useTranslation} from "react-i18next";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("escaping_the_friend_zone_title")
    const article = t("escaping_the_friend_zone_article1")
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
        </div>
    )
}

export default Intro