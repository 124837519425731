import {useTranslation} from "react-i18next";
import { imgUrl } from "./constants";
export const Lazy = () => {
    const { t } = useTranslation('app');
    return(
        <div className="child-container">
            {new Array(5).fill("").map((_,idx) => {
              return <> <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                    {t(`poses_title${21+idx}`)}
                </div>
                <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl['src'+(29+idx)]} alt="course-preview" />
                </div>
                <div className="child-container__article-title mt-16 typography-16">
            {t(`poses_article${28+idx}`)}
                </div>
               </>
            })
            }
        </div>
    )
}