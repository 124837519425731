export const imgUrl = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.5.1.png?alt=media&token=de0bea29-fa87-4531-a9bf-87fabf79915f",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.5.2.png?alt=media&token=c1702917-907b-46ba-ab81-326af2ba4e6a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.5.3.png?alt=media&token=0974b4d8-68d1-447d-8151-6a58b90a0775",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.5.4.png?alt=media&token=4a7dd0b5-eca0-430d-ad29-5b13687f03e7",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.5.5.png?alt=media&token=f18c1aa2-e23c-4b7a-aec8-0761fa8aecea",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.5.6.png?alt=media&token=9753dba4-cf31-4a3c-8675-5fd426916780",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.5.7.png?alt=media&token=f83a06d6-75a3-44c6-8090-199fa8cccd84",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.5.8.png?alt=media&token=aee999ce-5f25-4b3e-a3fc-0eef6a3b3435",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Premium%2F1.5.9.png?alt=media&token=e315fffb-3fc2-4e17-8fa4-d087c451b765",
]