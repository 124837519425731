import {signInWithEmailAndPassword, signOut} from "firebase/auth";
import {auth} from "../../../firebase/firebase.config";

interface ILogInWithEmailParams {
    email:string;
    password:string;
    successfulAuthRoute:()=>void;
    newAccountRoute:()=>void;
    confirmEmailRoute:()=>void;
}

export const logInUserAccount=({email, password, confirmEmailRoute, successfulAuthRoute,newAccountRoute}:ILogInWithEmailParams)=> {
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            if(user && user.emailVerified) {
                successfulAuthRoute();
                return;
            } else if(user && !user.emailVerified) {
                signOut(auth)
                confirmEmailRoute();
                return;
            } else {
                signOut(auth)
                newAccountRoute();
            }
        })
        .catch((error) => {
            newAccountRoute();
            const errorCode = error.code;
            const errorMessage = error.message;
        });
}
