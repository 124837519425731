export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    }
]

export const src1=[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.21.1.png?alt=media&token=8cded41d-8067-4347-bbf8-4f2f3eb8c5fc",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.21.2.png?alt=media&token=cbdeddf8-2712-49ed-b43d-f8f32cc8093f",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.21.3.png?alt=media&token=d41b86da-a025-4ad1-90d2-b52b29659f27"
]
