export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
]

export const images = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.7.1.png?alt=media&token=2a6d641e-7365-4c5f-a4c2-216d9380c56c",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.7.2.png?alt=media&token=1cf60703-ab55-4922-b741-a55492e6b919",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.7.3.png?alt=media&token=dce975b4-a54f-489e-8404-9797c3cdd892"
]



