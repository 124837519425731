import {Trans, useTranslation} from "react-i18next";
import {beEnticingOne} from "../../../assets/onBoardingImages";
import {ButtonThemes} from "../../../constants/button.constants";
import './EnticingOneScreen.scss';
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";

export const EnticingOneScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={beEnticingOne} />
            </ContentContainer>
            <p className={'enticingOneQuestion font-bebas'}>
                <Trans t={t}>{t("enticingOneScreen.question")}</Trans>
            </p>
            <NextRouteButton option={t("enticingOneScreen.agreeButtonText")}
                             route={nextRoute}
                             buttonTheme={ButtonThemes.DARK}
                             className={'enticingOneAgreeButton'} />
        </>
    )
}
