export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const images = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.10.1.png?alt=media&token=4476dc67-5c00-44f9-9f9b-205b8a989edc",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.10.2.png?alt=media&token=4b587807-8781-4dc5-806f-414a86178d45"
]
