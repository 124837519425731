import {useTranslation} from "react-i18next";
import {images} from "./constants";

const Lingerie = () => {
    const { t } = useTranslation('app');
    const title = t("irresistible_magnet_title")
    const subtitle = t("irresistible_magnet_subtitle1")
    const article = t("irresistible_magnet_article")
    const article1 = t("irresistible_magnet_article1")
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
        </div>
    )
}

export default Lingerie