export const MaintainConversationRoutes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
]
export const images1 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.1.2.png?alt=media&token=900c3970-edec-4128-8eed-3312dd0fc0e7"
]

export const maintain1Src = "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.1.1.png?alt=media&token=298d4714-50ae-4c5e-89b0-479dd1922821"