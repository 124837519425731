import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './CurrentRelationshipScreen.scss';
import {currentRelationship} from "../../../assets/onBoardingImages";
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";

export const CurrentRelationshipScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    const currentRelationshipAnswerOptions:string[]= [
        t("currentRelationshipScreen.breakingUpStageButton"),
        t("currentRelationshipScreen.perfectStageButton"),
        t("currentRelationshipScreen.difficultQuestionButton")];

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={currentRelationship} />
            </ContentContainer>
            <p className={'currentRelationshipQuestion font-bebas'}>{t("currentRelationshipScreen.stageCheckQuestion")}</p>
            <div>
                {currentRelationshipAnswerOptions.map((optionText)=>{
                    return  <NextRouteButton key={optionText} option={optionText}
                                     route={nextRoute}
                                     buttonTheme={ButtonThemes.DARK}
                                     className={'currentRelationshipAnswerButton'} />
                })}
            </div>
        </>
    )
}
