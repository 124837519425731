export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
    {
        tab: 10,
    },
    {
        tab: 11,
    },
    {
        tab: 12,
    },
    {
        tab: 13,
    }
]

export const images = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3%20Unleashing%20Role-Playing%20Scenarios.png?alt=media&token=9ccdb221-479f-4360-b260-803f544089af",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.1.1.png?alt=media&token=4503fed7-dbff-4c6a-af43-470c48384435",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.1.2.png?alt=media&token=aef9e2c3-c3b3-412a-87b1-854a095fb3e4",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.1.3.png?alt=media&token=8e6cc663-f2ad-4625-9e27-8678b9241ecc"
]

export const images1 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.2.1.png?alt=media&token=6c770306-c1fa-4605-b664-c6411179d741"
]

export const images2 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.3.1.png?alt=media&token=3734d706-263e-4b83-8e32-3d5b48dadb9d"
]

export const images3 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.4.1.png?alt=media&token=a538e1f1-487b-4987-9e14-2ce3f3f4fe5f"
]

export const images4 =[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.5.1.png?alt=media&token=ca460fb4-841b-4636-b2ff-22546d15a91a"
]
export const images5 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.6.1.png?alt=media&token=29d8437e-4d26-4137-999a-4b9dc80d3ee2"
]
export const images6 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.7.1.png?alt=media&token=3e83698a-3e87-4cce-8e72-6291edaf8eb9"
]
export const images7 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.8.1.png?alt=media&token=d63f04a4-3334-444a-9850-74eb1f16f14e"
]
export const images8 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.9.1.png?alt=media&token=a2ca6bbb-0a63-4f96-a7c9-dc12efde37d9"
]
export const images9 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.10.1.png?alt=media&token=fb1b0683-b7b4-4f61-8f6d-aef43c061a7b"
]
export const images10 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.11.1.png?alt=media&token=c4ab5f57-e3e2-4e1e-bb17-3f04b94959b3"
]
export const images11 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3.12.1.png?alt=media&token=6158ed67-f042-43d6-b82c-f8ba379c91e2"
]