import {useTranslation} from "react-i18next";
import {images} from "./constants";

const BackMassage = () => {
    const { t } = useTranslation('app');
    const list = t("cunnilingus_list1", { returnObjects: true })


    return(
        <div className="child-container">
            {list.map((i: { title: string, description: string }, idx: number) => {
                return <>
                    <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                        {i.title}
                    </div>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i.description}
                    </div>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={images[idx]} alt="course-preview" />
                    </div>
                </>
            })
            }

            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("cunnilingus_note")}
                </div>
            </div>
        </div>
    )
}

export default BackMassage