import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {TRUTH_OR_DARE_ADVANCED} from "../../contants";

export const GirlTruth = () => {
    const { t } = useTranslation('app');
    const list1 = t("premium_truth8_list", { returnObjects: true })
    const list2 = t("premium_truth8_list1", { returnObjects: true })
    const list3 = t("premium_truth8_list2", { returnObjects: true })
    const titleMain = t("truth_or_dare_advanced_premium").replaceAll('"', "")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("truth_or_dare_advanced") + 7]}
                        objectToSave={{
                            img: TRUTH_OR_DARE_ADVANCED[7].img,
                            value: 'truth_or_dare_advanced',
                            route: '/girl-truth',
                            index: 7
                        }}
                        uuid={"truth_or_dare_advanced7"}
                        route={"/premium/truth-or-dare-advanced"}
            />
        <div className="child-container">
            {list1.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            {list2.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            {list3.map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }

        </div>
            </>
            )
}