import {useTranslation} from "react-i18next";
import {src1} from "./constants";

export const Prisoner = () => {
    const { t } = useTranslation('app');
    const subtitle = t("bedroom_games_subtitle2")
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("bedroom_games_article2")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[2]} alt="course-preview" />
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("bedroom_games_note2")}
                </div>
            </div>
        </div>
    )
}