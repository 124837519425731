import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
   return(
        <div className="child-container">
            <div className="child-container__article-title typography-16">
                {t("game1_article")}
            </div>
            <div className="child-container__red-title font-bebas mt-16  typography-18 typography-bold">
                {t("game1_subtitle1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title typography-16">
                {t("game1_article1")}
            </div>
        </div>
    )
}

export default Intro