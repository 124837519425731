import {useTranslation} from "react-i18next";
import {taboo1Src} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("taboo_title")
    const list = t("taboo_list", { returnObjects: true })

    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            {list.map((i: { title: string, description: string }, idx: number) => {
                return <>
                    <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                        {i.title}
                    </div>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i.description}
                    </div>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={taboo1Src[idx]} alt="course-preview" />
                    </div>
                </>
            })
            }
        </div>
    )
}

export default Intro