import {useTranslation} from "react-i18next";
import {images1} from "./constants";

export const Part2 = () => {
    const { t } = useTranslation('app');
 return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title35")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_article5")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title36")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title37")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list17", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title38")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title39")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list18", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title40")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title41")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[2]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list19", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title42")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title43")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[3]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list20", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title44")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title45")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[4]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list21", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title46")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title47")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[4]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list22", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title48")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title49")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[5]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list23", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title50")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title51")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[6]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list24", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title52")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title53")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[7]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list25", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title54")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title55")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[8]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list26", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title56")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title57")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[9]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list27", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title58")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title59")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[10]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list28", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title60")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title61")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[11]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list29", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title62")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title63")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[12]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list30", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title64")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("course3_tests_title65")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[13]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("course3_tests_list31", {returnObjects:true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_article6")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title66")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_article7")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title67")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_article8")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("course3_tests_title68")}
            </div>
            <div className="child-container__article mt-24 typography-16">
                {t("course3_tests_article9")}
            </div>
        </div>
    )
}