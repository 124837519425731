import {useTranslation} from "react-i18next";
import '../TestResults.scss';
import {ButtonThemes} from "../../../../constants/button.constants";
import {NextRouteButton} from "../../../../components/NextRouteButton/NextRouteButton";
import { ContentContainer } from "../../../../components/onboarding/ContentContainer/ContentContainer";
import {OnBoardingImage} from "../../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {averageTestResult} from "../../../../assets/onBoardingImages";
export const AverageResultScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={averageTestResult} />
                <h3 className={'testResultsTitle font-bebas'}>{t("averageTestResultsScreen.title")}</h3>
                <p className={'testResultsText'}>{t("averageTestResultsScreen.text")}</p>
            </ContentContainer>
            <NextRouteButton option={t("averageTestResultsScreen.continueButtonText")}
                             route={nextRoute}
                             buttonTheme={ButtonThemes.DARK}
                             className={'testResultsContinueButton'} />
        </>
    )
}
