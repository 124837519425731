import {useTranslation} from "react-i18next";
import {src1} from "./constants";

export const Vibrator = () => {
    const { t } = useTranslation('app');
    const subtitle = t("enhancing_intimacy_subtitle3")
    const subtitle1 = t("enhancing_intimacy_subtitle4")
    const article = t("enhancing_intimacy_article3")
    const list = t("enhancing_intimacy_list4", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[2]} alt="course-preview" />
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[3]} alt="course-preview" />
            </div>
        </div>
    )
}