export const MASTERING_YOUR_MASCULINITY = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.1%20Building%20Self-Confidence.png?alt=media&token=e0f8ec50-9b74-45b9-ac62-e0155c8bae73'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.2%20Unleash%20Your%20Charisma.png?alt=media&token=6969c3e4-533b-4f73-9ea0-b669f878c11c'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.3%20The%20Art%20of%20Storytelling.png?alt=media&token=74b7ce23-736a-4254-a759-42a28a06a981'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.4%20Reading%20Body%20Language.png?alt=media&token=a8a0aa31-2c29-4831-93e9-bfb6e63a7d90'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.5%20Approaches%20to%20Initiating%20Connections.png?alt=media&token=c47ab655-a06e-495b-bca3-4f09b68c6a21'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.6%20Navigating%20Online%20Dating.png?alt=media&token=b74c3375-8bb8-4ee5-aa15-fbe8f6de1a11'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7%20Maintaining%20Engaging%20Conversations%2C%20Task.png?alt=media&token=949d9bb8-7e57-4d58-92f1-7261f6c5fde2'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.8%20Pathways%20to%20Successful%20Dating.png?alt=media&token=d0f8e345-d6e0-4b92-b393-63f6c8e16475'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.9%20Arousing%20Her%20Interest.png?alt=media&token=535a2a18-57b8-4d56-8782-a825bf8467c3'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.10%20Flirting%20with%20Impact%2C%20Task.png?alt=media&token=c97a2ed6-a6c1-48cb-9819-b35220d02149'
    }
] as const
export const PLEASURE_PATHWAYS = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.1%20Masterful%20Love%20Making%20Principles.png?alt=media&token=5e1c2049-e47e-4cc2-9584-eb28aa03c86e'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.2%2020%20questions%20to%20ask%20a%20girl%20on%20the%20first%20date.png?alt=media&token=9bab420d-a15c-4d30-ba65-2a46435697f2'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.3%20Her%20Orgasm.png?alt=media&token=d16c7bb7-8166-44be-82bd-d840eac97c75'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.4%20Understanding%20Female%20Libido.png?alt=media&token=371cb297-ab5c-4c4a-8a21-aa6b001af8ff'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.5%20Mastering%20Emotional%20Control.png?alt=media&token=7f99f335-5a6c-4182-b47a-598e63c13c61'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.6%20Escaping%20the%20Friend%20Zone_%20How%20to%20Win%20Her%20Heart.png?alt=media&token=b1e2838a-bc46-4104-99a1-fb59bf20c6eb'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.8%20Sizzling%20Sexting%20Techniques.png?alt=media&token=20548094-7ce4-4d9e-b0b7-075d9800a76d'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.9%20Tapping%20into%20Female%20Erogenous%20Zones.png?alt=media&token=13303d2a-8c14-446e-a103-edf1a03adbcd'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.10%20A%20Guide%20to%20Dick%20Pics.png?alt=media&token=b853aab1-4f2c-48c4-9e63-5dfccd32e008'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.11%20Taboo%20Topics_Phrases%20in%20Bed.png?alt=media&token=bd353a2c-6b9e-4cf8-acd7-42d54cf06f6d'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.12%20Secret%20Sensual%20Techniques.png?alt=media&token=ecb12dc3-0173-4617-a832-a4bda02fb47f'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.13%20A%20Guide%20to%20Pleasurable%20Oral%20Sex.png?alt=media&token=b11e2b21-70b8-46b9-9d1f-4458872a729e'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.14%20Turning%20Her%20On%20Without%20Touching.png?alt=media&token=c7a832d1-c949-44a6-9435-cdaa67067725'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.15%20Embracing%20Cunnilingus%20with%20Confidence.png?alt=media&token=6d968e6d-9e1f-40f3-8ff5-3d34d308b2d0'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.16%20Enhancing%20Intimacy%20with%20Toys.png?alt=media&token=ea6e4ddd-59d7-4764-8138-e19b839fa9ae'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.17%20Bedroom%20Games.png?alt=media&token=b37e6da0-c7ee-4ba2-b492-4ac958098e39'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.18%20Embracing%20Anal%20Pleasure.png?alt=media&token=feed5dfc-dca2-4040-a827-02e6bf290a46'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.19%20Exploring%20Threesomes.png?alt=media&token=7dd7d051-20f7-4b19-ad24-b3e63e09051a'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20%20Top%20Positions%20for%20Ultimate%20Pleasure.png?alt=media&token=0d19fec5-a6b6-41c7-b030-37cfba086a28'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.21%20Erotic%20Role-Play%20Scenarios.png?alt=media&token=9c1a2718-87f9-46f2-b4aa-aee392c4007e'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.25%20BDSM%20Insights.png?alt=media&token=9930f25f-ffb1-467e-ab56-e33cbc4b92c3'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.13%20A%20Guide%20to%20Pleasurable%20Oral%20Sex.png?alt=media&token=b11e2b21-70b8-46b9-9d1f-4458872a729e'
    },
] as const
export const EROTIC_MASTERY = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.1%20Irresistible%20Texting%20Seduction.png?alt=media&token=a2153c3e-3f00-4ff1-8615-93fe1c824bad'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.2%20Thrilling%20Quest.png?alt=media&token=49f21837-814d-436b-8652-7f980712a31c'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.3%20Unleashing%20Role-Playing%20Scenarios.png?alt=media&token=9ccdb221-479f-4360-b260-803f544089af'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.4%20Playful%20Tests.png?alt=media&token=848b8bc4-5647-4dcd-9d14-ed2e36ced29c'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5%20Techniques%20for%20Ultimate%20Pleasure.png?alt=media&token=4169bb98-f57e-4f09-9dd6-78b9223f14d7'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.6%20Positions%20for%20Mind-Blowing%20Pleasure.png?alt=media&token=f6f3d1f3-fa56-447a-82e7-b8d234847066'
    },
] as const
export const WORKOUT_CHALLENGE = [
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2F30%20Days%2F4.1%20Practices%20to%20Embrace%20Your%20Masculine%20Essence.png?alt=media&token=754f51b5-da70-450e-aa89-1c07736bacec'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2F30%20Days%2F4.2%20Day%201%20-%20Day%2030.png?alt=media&token=6f173906-77eb-436c-8ca9-22b19f925dde'
    },
] as const

export const MASTERING_YOUR_MASCULINITY_ROUTES = [
    '/building-self-confidence',
    '/unleash-your-charisma',
    '/the-art',
    '/reading-body-language/1',
    '/approaches-to-connect/1',
    '/navigation-online/1',
    '/maintaining',
    '/pathways-to-success/1',
    '/arousing-her-interests/1',
    '/flirting-with-impact/1'
]

export const PLEASURE_PATHWAYS_ROUTES = [
    '/masterful-love',
    '/questions',
    '/her-orgasm/1',
    '/libido/1',
    '/mastering-emotional-control/1',
    '/escaping/1',
    '/preparation/1',
    '/sizzling/1',
    '/phrases-for-hot-messaging/1',
    '/guide/1',
    '/taboo',
    '/sensual-techniques',
    '/secrets-techniques/1',
    '/turning-her/1',
    '/embracing-cunnilingus',
    '/sex-toys/1',
    '/sex-games/1',
    '/anal-sex/1',
    '/threesome/1',
    '/top-poses/1',
    '/sex-scenarios/1',
    '/bdsm/1',
]

export const EROTIC_MASTERY_ROUTES = [
    '/hot-phrases',
    '/guest/1',
    '/role-playing/1',
    '/tests/1',
    '/common-techniques/1',
    '/poses/1',
]


export const WORKOUT_CHALLENGE_ROUTES = [
    '/practices/1',
    '/days'
]
export const MASTERING_YOUR_MASCULINITY_NUMBER = '0'

export const PLEASURE_PATHWAYS_NUMBER = '1'

export const EROTIC_MASTERY_NUMBER = '2'


export const WORKOUT_CHALLENGE_NUMBER = '3'