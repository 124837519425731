import {useTranslation} from "react-i18next";
import {InfoDarkBox} from "../../InfoDarkBox/InfoDarkBox";
import {IInfoBoxData} from "../../../types/infoDarkBox.types";
import {ReactComponent as SexCalendarLogo } from "assets/app/SexLogo.svg";
import {ReactComponent as TopPositionsLogo } from "assets/app/TopPositions.svg";
import {ReactComponent as OralSexLogo } from "assets/app/OralSex.svg";
import {ReactComponent as HardModeLogo } from "assets/app/HardMode.svg";
import {ReactComponent as TipsLogo } from "assets/app/Tips.svg";
import {ReactComponent as UltimateLogo } from "assets/app/Ultimate.svg";
export const SignUpOfferGamesContainer =()=>{
    const { t } = useTranslation('onboarding');

    const gamesData:IInfoBoxData[]=[
        {logoSrc:<TopPositionsLogo/>,title:t("sighUpOfferScreen.games.truthOrDareGame")},
        {logoSrc:<SexCalendarLogo/>,title:t("sighUpOfferScreen.games.sexGame")},
        {logoSrc:<OralSexLogo/>,title:t("sighUpOfferScreen.games.oralSexGame")},
        {logoSrc:<TipsLogo/>,title:t("sighUpOfferScreen.games.hotTasksGame")},
        {logoSrc:<UltimateLogo/>,title:t("sighUpOfferScreen.games.SMSGame")},
        {logoSrc:<HardModeLogo/>,title:t("sighUpOfferScreen.games.hardModeGame")}
    ]

    return (
           <InfoDarkBox infoData={gamesData} title={t("sighUpOfferScreen.games.title")} />
    )
}
