export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7,
    },
    {
        tab: 8,
    },
    {
        tab: 9,
    },
    {
        tab: 10,
    },
    {
        tab: 11,
    },
    {
        tab: 12,
    }
]

export const src1=[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.1.png?alt=media&token=ce23c62e-c1e7-4e1e-b7d7-0321bd374e1a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.2.png?alt=media&token=29524672-cd20-4706-886b-3ae1507e9266",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.3.png?alt=media&token=29bd50a2-4c66-416d-9184-708adda9597d",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.4.png?alt=media&token=cbf08365-d0f2-49f2-b321-b45cbde48602",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.5.png?alt=media&token=7bbe159e-5c8d-4902-8b61-016ee79e9251",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.6.png?alt=media&token=3408f94a-27d5-49e3-8dc9-68057f6cea38",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.7.png?alt=media&token=32492b2f-592a-4a17-a895-f6dc9a8828fa",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.8.png?alt=media&token=ee43fa12-ce76-49e0-bc01-2e9fd4b60618",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.9.png?alt=media&token=7a52b924-2af2-4786-b692-f9dbd5efb26c",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.10.png?alt=media&token=d424a6fe-224d-4be9-a9e1-b3bf3155946e",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.11.png?alt=media&token=8fee1d60-6e01-44bd-963a-633caf0eece3",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.20.12.png?alt=media&token=77ccd2ff-e45e-4a2e-bd74-d857bf82ff44"
]