import {useTranslation} from "react-i18next";
import {src1} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("bedroom_games_title")
    const article = t("bedroom_games_article")
    const article1 = t("bedroom_games_article1")
    const subtitle = t("bedroom_games_subtitle")
    const list = t("bedroom_games_list", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={src1[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article1}
            </div>
        </div>
    )
}

export default Intro