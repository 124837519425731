export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    }
]

export const images = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.13.1.png?alt=media&token=340d4ceb-b383-4f07-8226-5b264d08fa3b",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.13.2.png?alt=media&token=1d84c4ef-f43d-4384-8421-cd43c8fb5588",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.13.3.png?alt=media&token=81cb2177-a5ee-43db-9ca8-5a109391eef3",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.13.4.png?alt=media&token=27e39f56-3260-4f72-81c5-509944d9e1a5",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.13.5.png?alt=media&token=f67aaec7-60e8-4401-99c2-ad626acf1980"
]



