import './MoneyBackContainer.scss';
import {moneyBackGuarantee, moneyBackGuaranteeLogo} from "../../../assets/onBoardingImages";
import {OnBoardingImage} from "../OnBoardingImage/OnBoardingImage";
import {ShadowContainer} from "../ShadowContainer/ShadowContainer";
import {TFunction} from "i18next";
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";
import {ReactComponent as MoneyBack} from  'assets/onBoardingImages/money.svg'

export const MoneyBackContainer =({t}:{t:TFunction})=>{
    return (
        <div className={'moneyBackContainer'}>
        <ShadowContainer>
            <OnBoardingImage imageSrc={moneyBackGuarantee} />
            <div className={'moneyBackInfoContainer'}>
                <MoneyBack />
            <h4>{t("payInfoScreen.moneyBack.title")}</h4>
            <br/>
            <p>{t("payInfoScreen.moneyBack.text")}</p>
            <br/>
            <p>{`${t("payInfoScreen.moneyBack.learnMoreText")} `}
                <a href={PolicyAnchors.SUBSCRIPTION}>{t("payInfoScreen.subscriptionTermsAnchor")}</a>
            </p>
            </div>
        </ShadowContainer>
        </div>
    )
}
