import {useTranslation} from "react-i18next";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {ButtonThemes} from "../../../constants/button.constants";
import './PartnerOpennessScreen.scss';
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";

interface IOpennessOptionsWithRoutes {
    option: string;
    route: string;
    buttonTheme: ButtonThemes;
    onClickFn: ()=>void
}

interface IPartnerOpenness {
    setIsTrustingRelationship:(isTrustingRelationship:boolean)=>void,
    nextRoutes:string[]
}
export const PartnerOpennessScreen =({setIsTrustingRelationship,nextRoutes}:IPartnerOpenness)=>{
    const { t } = useTranslation('onboarding');

    const partnerOpennessOptionsWithRoutes:IOpennessOptionsWithRoutes[] = [
        {option:t("checkPartnerOpennessScreen.agreeButtonText"),route:nextRoutes[0],
            buttonTheme:ButtonThemes.DARK, onClickFn: ()=> setIsTrustingRelationship(true)},
        {option:t("checkPartnerOpennessScreen.disagreeButtonText"),route:nextRoutes[1],
            buttonTheme:ButtonThemes.DARK, onClickFn: ()=> setIsTrustingRelationship(false)}];

    return (
        <>
            <ContentContainer>
                <h3 className={'partnerOpennessTitle font-bebas'}>{t("checkPartnerOpennessScreen.title")}</h3>
                <p className={'partnerOpennessText'}>{t("checkPartnerOpennessScreen.text")}</p>
                <h6 className={'partnerOpennessQuestion font-bebas'}>{t("checkPartnerOpennessScreen.question")}</h6>
            </ContentContainer>
            <div>
                {partnerOpennessOptionsWithRoutes.map(
                    ({onClickFn, option,route, buttonTheme},index)=>{
                    return <NextRouteButton
                        key={option}
                        onClick={onClickFn}
                        option={option}
                        route={route}
                        buttonTheme={buttonTheme}
                        className={'partnerOpennessOptionButton'}/>
                })}
            </div>
        </>
    )
}
