import {useTranslation} from "react-i18next";
import {images3} from "./constants";

const Step2 = () => {
    const { t } = useTranslation('app');
    const title = t("her_orgasm_subtitle3")
    const list = t("her_orgasm_list3", { returnObjects: true })
    return (
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {title}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[0]} alt="course-preview" />
            </div>
        </div>
    )
}

export default Step2