import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import {getStorage} from "firebase/storage";
import { getAuth, updatePassword } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
        // apiKey: process.env.REACT_APP_apiKey,
        // authDomain: process.env.REACT_APP_authDomain,
        // databaseURL: process.env.REACT_APP_databaseURL,
        // projectId: process.env.REACT_APP_projectId,
        // storageBucket: process.env.REACT_APP_storageBucket,
        // messagingSenderId: process.env.REACT_APP_messagingSenderId,
        // appId: process.env.REACT_APP_appId,
        // measurementId: process.env.REACT_APP_measurementId
    apiKey: "AIzaSyDlD3er2tc4x1PUqirwtqGgRG3XQICqZpk",
    authDomain: "manmastery-d7024.firebaseapp.com",
    databaseURL: "https://manmastery-d7024-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "manmastery-d7024",
    storageBucket: "manmastery-d7024.appspot.com",
    messagingSenderId: "149879154114",
    appId: "1:149879154114:web:10f2178eda843d4b11a25b",
    measurementId: "G-T5VMXHPC4Y"
};

export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);

export const storageFirebase = getStorage();
export const db = getDatabase();

export const auth = getAuth();

export const googleProvider = new GoogleAuthProvider();

//
// {
//     "rules": {
//     "usersData": {
//         "$uid": {
//             ".read": "auth !== null",
//                 ".write": "auth.uid === $uid || auth.uid === 'A5ZnjundqyepzXodG2uMkP8GNov1'"
//         }
//     },
//     "usersEmails": {
//         ".read": "false",
//             ".write": "auth.uid === 'A5ZnjundqyepzXodG2uMkP8GNov1'",
//     },
// },
// }

