export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
    {
        tab: 6,
    },
    {
        tab: 7
    }
]

export const images = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.1.1.png?alt=media&token=11b6140b-c283-4597-9e4b-2730828a7051",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.1.2.png?alt=media&token=f688d9d3-8d87-4600-b257-65ef60973941",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.1.3.png?alt=media&token=06ecb669-b24a-4d3a-b1e1-bef75caa28c8"
]

export const images1 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.2.1.png?alt=media&token=628ce941-9864-4853-8433-2e525297de0e",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.2.2.png?alt=media&token=86d5e99f-3808-4120-87d1-161810afa126",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.2.3.png?alt=media&token=1b2aacbb-8d03-4392-81de-3a1dc1c4b6a4"
]

export const images2 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.3.1.png?alt=media&token=c313ad13-4cfa-4ca8-aa96-0046f101a92a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.3.2.png?alt=media&token=aa742552-d885-4ae8-9415-41f5751b40bf",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.3.3.png?alt=media&token=949485c2-aa21-46a3-9fa7-1ded00e507cc",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.3.4.png?alt=media&token=33f3d0b2-e2c2-4ca4-a544-f4d7e203d8ad",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.3.5.png?alt=media&token=b59e66d2-de45-4d23-a815-477379ae4613",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.3.6.png?alt=media&token=6ec724f3-425b-4a43-b0e5-ab2ebd87dd31",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.3.7.png?alt=media&token=cd0ed607-919b-4a64-b541-4ae90bb30663",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.3.8.png?alt=media&token=f5eeeb60-9dd6-4f59-84d1-d8767681fba2",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.3.9.png?alt=media&token=73b7c25b-a963-4451-bf81-b5453651fffb",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.3.10.png?alt=media&token=566d84f7-4a61-4f1d-80a7-a37f51ac9b4b"
]

export const images3 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.1.png?alt=media&token=56a71ac2-b445-4fdd-9943-0a49d21ea2bb",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.2.png?alt=media&token=f9bef685-f944-4599-9489-336704c0bb53",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.3.png?alt=media&token=51ea62ee-b253-4411-85ba-4b97e39c2f4a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.4.png?alt=media&token=197bed40-3c36-405d-8736-4557378f71b8",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.5.png?alt=media&token=4049dd20-eeff-4aa6-8308-442f6fdba599",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.6.png?alt=media&token=09d3a361-abea-4ea2-97fa-734743f19116",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.7.png?alt=media&token=4e6b0f96-1219-42a2-8c2f-aaf96ae77cf9",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.8.png?alt=media&token=8188ab2b-f9fd-4129-854b-93308e56cdd5",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.9.png?alt=media&token=2d8172c9-dca8-48cf-81fb-59f921449338",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.10.png?alt=media&token=8223db71-d6bf-452f-9b95-e5b7a4de4f04",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.11.png?alt=media&token=76599a16-f92b-45b8-97d9-08055a019b88",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.12.png?alt=media&token=3c08c722-1c54-4f23-b81b-dbe937bd60c6",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.13.png?alt=media&token=4ca465ee-15d6-43fa-b2ad-ee9cfda22efa",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.4.14.png?alt=media&token=ad08b76c-c63f-4293-a662-c1a3e5aa3f1b"
]

export const images4 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.5.1.png?alt=media&token=f739f7bd-223b-40bf-88f4-ea0714c38ee9",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.5.2.png?alt=media&token=ad66ef36-8521-4bee-88f7-ef9e8068b4c5",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.5.3.png?alt=media&token=23bce501-5440-41e4-802e-735848a87892",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.5.4.png?alt=media&token=a474a499-78ee-41c6-9d7c-57b626f014d5",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.5.5.png?alt=media&token=2f0ecb8d-e36e-4d82-9cef-2ee7b9899435"
]


export const images5 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.5.6.1.png?alt=media&token=a90ede7e-99b7-4523-abad-e6d9ff418edb"
]




