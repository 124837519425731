export const ManOfMyDreamRoutes = [
    {
        tab: 1,
    },
]

export const images1 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.1.1.png?alt=media&token=c2cbe953-0d4d-4e46-8c10-a4d7ff024a57",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.1.2.png?alt=media&token=5d9bf309-0ced-43db-972a-4f47ccf30953",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.1.3.png?alt=media&token=0067a442-c8db-4cdf-9444-40ea06fbeaf3",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.1.4.png?alt=media&token=a31fa7dc-7abf-4629-ae31-d9695b56c7f8",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.1.5.png?alt=media&token=25e0af93-8e76-4fa1-899c-ca30e3cfc7b5",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.1.6.png?alt=media&token=45800e88-751c-4c8b-be24-b6c8d5c4eb7a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.1.7.png?alt=media&token=20aa9990-3e7a-4568-9ca7-c38b8a3183bb"
]

export const images2 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.3.1.png?alt=media&token=6a94f429-b006-4b89-852f-34656288dba9"
]

export const images3 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.4.1.png?alt=media&token=2943406d-ada4-46c2-bca8-27cba34bef0a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.4.2.png?alt=media&token=43b7e57c-d8a9-434d-a6e1-640c418409be",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.4.3.png?alt=media&token=15e18697-775d-4384-adb1-29950f80deaa",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.4.4.png?alt=media&token=b5ac5714-ac89-493a-b704-4bf4d4a353aa",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.4.5.png?alt=media&token=69bed18d-ed7c-4803-8898-fa25e947dd36",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.4.6.png?alt=media&token=d094262b-290a-4a80-bc4c-64020011e2fb",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.4.7.png?alt=media&token=c248340d-fd44-46dc-8018-4aa4d3a59342",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.4.8.png?alt=media&token=e6b33c63-47fb-4aa1-bf95-300f57fc8543"
]

export const images4 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.5.1.png?alt=media&token=eed69b27-182c-4df8-bc0c-5479b44afbb7",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.5.2.png?alt=media&token=2dfd8ec6-7666-4813-8c59-5313b7db8966",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.5.3.png?alt=media&token=ce1e5c17-54a9-486e-a86a-a84db2eca8a1",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.5.4.png?alt=media&token=260d3d46-89b1-4849-b8eb-4828794cc509",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.5.5.png?alt=media&token=61be2922-48b5-4cc6-abc7-93c42e0b2028"
]

export const images5 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.6.1.png?alt=media&token=e2df485f-5bb3-4fbc-b280-fd5005477116",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.6.2.png?alt=media&token=7fc9cd34-c80d-41c4-bc02-1ae532143d42",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.6.3.png?alt=media&token=3e1d80db-c02d-4787-bc35-3f6f4c213aac",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.6.4.png?alt=media&token=a38859ec-07c8-4735-8eab-fa68f5802daf"
]

export const images6 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.1.png?alt=media&token=22d1f0ba-fe6d-47ba-a4da-20fea6faa558",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.2.png?alt=media&token=d51707db-6939-4ba3-90da-454cab22edb3",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.3.png?alt=media&token=44115a03-f21e-4a0a-8f46-72f11ce36da6",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.4.png?alt=media&token=c36073d5-2a70-4f22-9d1d-185bcf9ea59f",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.5.png?alt=media&token=8970c3f5-7f8c-425c-96d8-9c03f3ea5d8a",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.6.png?alt=media&token=13fe90c4-2ca2-473d-9110-f9bb0b481ba1",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.7.png?alt=media&token=6426e788-5bca-490d-b29e-3fda96c384bd",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.8.png?alt=media&token=4c9af7b9-b52b-40e6-b85a-9184c23b35ca",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.9.png?alt=media&token=4cc1ff8d-68f4-483d-9104-3e7d003e9df4",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.10.png?alt=media&token=f1e4c7a7-6740-48e7-93ac-afec8e6ceda3",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.11.png?alt=media&token=3ed55dc1-3d0b-4e9b-ad5b-e21a14744171",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.7.12.png?alt=media&token=a9856432-3bc5-4cfd-b73d-a8b48baeb06c"
]

export const images7 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.8.1.png?alt=media&token=dca6e479-a915-40e6-8059-6ca9da77d3e7",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.8.2.png?alt=media&token=47327327-2390-4f1d-800f-4f9d459edf5a",
]

export const images8 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.9.1.png?alt=media&token=17c83602-f68a-42d8-9a4e-9d2718547949",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.9.2.png?alt=media&token=98f2ba6a-25ca-4362-989e-343c86e7d580",
]

export const images9 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.10.1.png?alt=media&token=5810c9d5-d13b-4bfe-a250-774490042fb7",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.10.2.png?alt=media&token=c79bc2a3-ed78-4be8-9ba7-64f370ff09a6",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.10.3.png?alt=media&token=1c261863-1e7a-4741-9761-cbecf6f75a84"
]

export const modelSrc = "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FMastering%2F1.2.1.png?alt=media&token=89257723-4f85-4e06-8670-b23a628f7348"
