import {useTranslation} from "react-i18next";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("cunnilingus_title")
    const list = t("cunnilingus_list", { returnObjects: true })

    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("cunnilingus_subtitle")}
            </div>
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
        </div>
    )
}

export default Intro