import {Trans, useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './EmailCheckScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {useState} from "react";
import {Input} from "../../../components/lego/Input/Input";
import {Checkbox} from "../../../components/lego/Checkbox/Checkbox";
import {writeUserEmailToDatabase} from "../../../utils/firebase/usersEmails.utils";
import {isEmailValid, isEmptyInput} from "../../../utils/login.utils";
import {FormErrorMessages} from "../../../constants/logInErrorMessages.constants";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";
import {useNavigate} from "react-router";
import {Button} from "../../../components/lego/Button/Button";

const EMAIL_ID='email'
export const EmailCheckScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');
    const navigate=useNavigate()
    const [isNewsletterNeeded,setIsNewsletterNeeded]=useState(false)
    const [email,setEmail]=useState('')

    const {errorState, setErrorState, clearErrorMessage}=useLogInErrorLogic()
    const onEmailFormSend=()=>{
       if(!isEmptyInput(email)) {
           setErrorState({message:t(FormErrorMessages.EMPTY_EMAIL),errorInputId:EMAIL_ID})
           return;
       }

       if(!isEmailValid(email)) {
           setErrorState({message:t(FormErrorMessages.NOT_VALID_EMAIL),errorInputId:EMAIL_ID})
           return;
       }

       writeUserEmailToDatabase(email, isNewsletterNeeded).then(()=>{
           navigate(nextRoute)
       })
   }

    const clearErrorData=(value:string, setInputValue: (inputValue:string)=>void)=>{
        clearErrorMessage()
        setInputValue(value)
    }

    return (
        <>
            <h4 className={'emailCheckTitle font-bebas'}>
                <Trans t={t} className="font-bebas">{t("emailScreen.text")}</Trans>
            </h4>
            <ContentContainer>
                <div className={'emailForm'}>
                    <Input onChange={(value)=>clearErrorData(value,setEmail)}
                           id={EMAIL_ID}
                           inputType={'email'}
                           labelText={t("emailScreen.emailLabel")}
                           placeholderText={''}
                           errorMessage={errorState.errorInputId===EMAIL_ID?errorState.message:''}/>

                    <Checkbox labelText={t("emailScreen.newsletterText")}
                              isChecked={isNewsletterNeeded}
                              setIsChecked={setIsNewsletterNeeded} />
                </div>
            </ContentContainer>

            <Button onClick={onEmailFormSend} text={t("emailScreen.continueButtonText")}
                    buttonTheme={ButtonThemes.DARK} width={100}
                    className={'emailCheckContinueButton'}
                     />
        </>
    )
}
