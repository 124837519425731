export const imgUrl = [
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.1.png?alt=media&token=13ed2fbd-1055-4752-b686-794ba6b8d819",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.2.png?alt=media&token=000349e4-3170-46f6-9ff5-d6e0e8f19581",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.3.png?alt=media&token=09c05620-0fa7-4181-80b4-7b96ef32fbea",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.4.png?alt=media&token=856decca-30cc-47f3-86ec-2e78c55d456d",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.5.png?alt=media&token=8e5b3bcd-196f-4338-b2c3-61a9b972463d",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.6.png?alt=media&token=2ea889b9-8e41-42b5-8b44-ef2ae90b9ba7",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.7.png?alt=media&token=1561aa3c-0b4e-4273-abf9-dc3b0cdfb245",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.8.png?alt=media&token=1b09ee95-2ec9-4d73-97f5-4bfc6cbd6076",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.9.png?alt=media&token=e6d1502f-0c2a-47ed-b6f4-6e919db7634a",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.10.png?alt=media&token=814004ec-2020-4bbc-bf58-18f79486f6dc",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.11.png?alt=media&token=29d5a5c9-10ac-4be8-9031-a3bdd8d74522",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.12.png?alt=media&token=95a3a030-74a3-437e-86bf-4f4ee4da0659",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.13.png?alt=media&token=4f5a5a1f-6a84-4a60-81e7-0fb96ef26846",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.14.png?alt=media&token=ccab7de8-49fb-4cea-9827-d9fedaae5494",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.15.png?alt=media&token=a731d72c-bfb9-4bcf-b62a-d21ede949969",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.16.png?alt=media&token=d4a55774-f04f-4718-aec7-699f4d6cbc91",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.17.png?alt=media&token=0d5e1609-296c-4f74-8e6e-60ecb6dabdbe",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.18.png?alt=media&token=4cfeaf70-d6a4-42f4-b6e0-0b7e6ab96cb5",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.19.png?alt=media&token=445b750c-c424-471d-aaa1-c54d57f67d77",
        "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FDecide%2F7.2.20.png?alt=media&token=c2b17cd2-300f-4502-9628-1456f5169ad1",
]





