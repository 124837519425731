import {useTranslation} from "react-i18next";
import {images3} from "./constants";
export const BlowJob = () => {
    const { t } = useTranslation('app');

    return(
        <>
        <div className="child-container">
            <div className="child-container__article-title typography-16">
                {t("techniques_article39")}
            </div>


            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title36")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article40")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title37")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article41")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title38")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[2]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article42")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title39")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[3]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article43")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title40")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[4]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article44")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title41")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[5]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article45")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title42")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[6]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article46")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title43")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[7]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article47")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title44")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[8]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article48")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title45")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[9]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article49")}
            </div>


            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title46")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[10]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article50")}
            </div>


            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title47")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[11]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article51")}
            </div>


            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title48")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[12]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("techniques_article52")}
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("techniques_title49")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images3[13]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("techniques_list3", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>


        </div>
            </>
    )
}