import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './SignUpOfferScreen.scss';
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {SignUpOffersContainer} from "../../../components/onboarding/SignUpOffersContainer/SignUpOffersContainer";
import {
    SignUpOfferGamesContainer
} from "../../../components/onboarding/SignUpOfferGamesContainer/SignUpOfferGamesContainer";
import {PaymentModal} from "../../../components/onboarding/PayInfoContainer/PaymentModal";
import {useEffect, useState} from "react";


interface ISignUpOffersWithRoutes {
    option: string;
    buttonTheme: ButtonThemes;
    onClickFn: ()=>void
    notLink?: boolean
}
export const SignUpOfferScreen =({nextRoute}: { nextRoute:string })=>{
    const { t } = useTranslation('onboarding');

    const [open, setIsOpen] = useState(false)

    const handleOpenPaymentModal= () => setIsOpen(false)

    const partnerOpennessOptionsWithRoutes:ISignUpOffersWithRoutes[] = [
        {option:t("sighUpOfferScreen.getDiscountButtonText"),
            buttonTheme:ButtonThemes.DARK, onClickFn: () => handleOpenPaymentModal()},
        {option:t("sighUpOfferScreen.skipButtonText"),
            buttonTheme:ButtonThemes.LIGHT, onClickFn: ()=> console.log('not pay')}];

    return (
        <>
            <SignUpOffersContainer />
            <SignUpOfferGamesContainer />
            <div>
                {partnerOpennessOptionsWithRoutes.map(
                    ({onClickFn, option, buttonTheme, notLink},index)=>{
                        return <NextRouteButton
                            key={option}
                            onClick={onClickFn}
                            option={option}
                            route={nextRoute}
                            buttonTheme={buttonTheme}
                            notLink={notLink}
                            className={'partnerOpennessOptionButton'}/>
                    })}
            </div>
            <footer className={'signInOfferFooter'}>
                <p>{t("sighUpOfferScreen.conditionOfPlanText")}</p>
                <br/>
                <p>{t("sighUpOfferScreen.paymentInfoText")}</p>
            </footer>
            <PaymentModal open={open} setOpen={setIsOpen} price={9.99} />
        </>
    )
}
