export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    },
    {
        tab: 5,
    },
]

export const images = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.2.1.png?alt=media&token=24829b0f-02a2-4d27-9e0a-c69cb5ef4bbe",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.2.1.1.png?alt=media&token=bcdd6026-12a6-4b16-b684-a434c78db5d1",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.2.1.2.png?alt=media&token=6e2a3f58-7502-4f6b-92df-483bb019539d",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.2.1.3.png?alt=media&token=668faf37-b87e-479a-ac88-133d199447bb",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FErotic%20Mastery%2F3.2.1.4.png?alt=media&token=cb82a064-4b7c-4cae-bb0e-47ca2fd8cee6"
]


