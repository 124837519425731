import {useTranslation} from "react-i18next";
import {images1} from "./constants";

const Intro = () => {
    const { t } = useTranslation('app');
    const title = t("sizzling_sexting_title")
    const list1 = t("sizzling_sexting_list1", { returnObjects: true })
    const list2 = t("sizzling_sexting_list2", { returnObjects: true })
    return(
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("sizzling_sexting_article")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("sizzling_sexting_subtitle1")}
            </div>
            <div className="child-container__list">
                <ul>
                    {list1.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("sizzling_sexting_subtitle2")}
            </div>
            <div className="child-container__list">
                <ul>
                    {list2.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__article-title mt-24 typography-14">
                {t("sizzling_sexting_article2")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[0]} alt="course-preview" />
            </div>
        </div>
    )
}

export default Intro