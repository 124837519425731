import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";

export const BeingShy = () => {
    const { t } = useTranslation('app');
 return(
        <div className="child-container">
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {t("game1_subtitle3")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title typography-16">
                {t("game1_article3")}
            </div>

        </div>
    )
}