import {useTranslation} from "react-i18next";
import '../FirstDateRule/styles.scss'
import {images1, maintain1Src} from "./contstants";
const Masterfull = () => {
    const { t } = useTranslation('app');
    const article = t("masterful_love_article")
    return(
        <div className="child-container">
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
            <div className="child-container__list">
                {t("masterful_love_list1", { returnObjects: true }).map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={maintain1Src} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("masterful_love_list2", { returnObjects: true }).map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("masterful_love_list3", { returnObjects: true }).map((i: string) => <div className="child-container__list mt-16 typography-14">{i}</div>)}
            </div>
        </div>
    )
}

export default Masterfull