import Behaviour from "./Behaviour";
import './styles.scss'
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useTranslation} from "react-i18next";
import {MASTERING_YOUR_MASCULINITY} from "../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {getAuth} from "firebase/auth";


const AllFirstDateRule = () => {
    const { t } = useTranslation('app');
    const title = t("mastering_your_masculinity_course")
    const user = getAuth()
    const [list, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!list[("MASTERING_YOUR_MASCULINITY") + 0]}
                        objectToSave={{
                            img: MASTERING_YOUR_MASCULINITY[0].img,
                            value: 'MASTERING_YOUR_MASCULINITY',
                            route: '/building-self-confidence',
                            index: 0
                        }}
                        uuid={"MASTERING_YOUR_MASCULINITY0"}
                        route={"/courses/mastering-your-masculinity"}
            />
     <Behaviour />
        </>
    )
}

export default AllFirstDateRule