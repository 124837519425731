import {useTranslation} from "react-i18next";
import { imgUrl } from "./constants";
export const Advanced = () => {
    const { t } = useTranslation('app');
    const subtitle = t("poses_title31")
    const article =t(`poses_article38`)
    return(
        <div className="child-container">
            {new Array(5).fill("").map((_,idx) => {
              return <> <div className="child-container__red-title font-bebas font-bebas mt-24 typography-18 typography-bold">
                    {t(`poses_title${26+idx}`)}
                </div>
                <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl['src'+(34+idx)]} alt="course-preview" />
                </div>
                <div className="child-container__article-title mt-16 typography-16">
            {t(`poses_article${33+idx}`)}
                </div>
               </>
            })
            }
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article}
            </div>
        </div>
    )
}