import {useParams} from "react-router";
import {Route, Routes} from "react-router-dom";
import Intro from "./Intro";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import '../../FirstDateRule/styles.scss'
import {misconceptionRules} from "./constants";
import Part1 from "./part1";
import Part2 from "./Part2";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PLEASURE_PATHWAYS} from "../../../CourseItem/contants";
const Misconceptions = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("pleasure_pathways_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.uid)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("pleasure_pathways") + 5]}
                        objectToSave={{
                            img: PLEASURE_PATHWAYS[5].img,
                            value: 'pleasure_pathways',
                            route: '/escaping/1',
                            index: 5
                        }}
                        uuid={"pleasure_pathways5"}
                        route={"/courses/pleasure-pathways"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Part1 />} />
                <Route path={'/3'} element={<Part2 />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={misconceptionRules} routeToNavigate={COURSE_ROUTES.ESCAPING}  />
        </>
    )
}

export default Misconceptions