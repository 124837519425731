import './InfoDarkBox.scss';
import React, {useContext} from "react";
import {IInfoBoxData} from "../../types/infoDarkBox.types";
import {isLastArrayElementAnOddNumber} from "../../utils/count.utils";
import {ThemeContext} from "../../context/themeContext";
import cn from "classnames";

interface IInfoDarkBoxProps {
    title:string;
    infoData: IInfoBoxData[];
    infoFooterText?:string;
}
export const InfoDarkBox =({title,infoData,infoFooterText}:IInfoDarkBoxProps)=>{
    const { theme } = useContext(ThemeContext);
    return (
        <div className={cn('darkBox',theme)}>
            <h4 className={'darkBoxTitle'}>{title}:</h4>
            <div className={'infoBoxContainer'}>
                {infoData.map(({logoSrc, title},index)=>{
                    return <div key={title} className={`infoPointContainer 
                    ${isLastArrayElementAnOddNumber(infoData.length,index)? 'infoPointContainerLastChild':''}`}>
                        {logoSrc}
                        <p>{title}</p>
                    </div>
                })}
            </div>
            {infoFooterText?<h6 className={'darkBoxFooter'}>{infoFooterText}...</h6>:null}
        </div>
    )
}
