export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]
export const images1 = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.9.1.png?alt=media&token=c12e210a-9b79-4443-8181-2f97168c3738",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.9.2.png?alt=media&token=c6ea7a77-72c5-4596-9859-ff2b088c8e79"
]

