export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
    {
        tab: 3,
    },
    {
        tab: 4,
    }
]

export const imgUrl = [
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2F30%20Days%2F4.1.1.png?alt=media&token=36243951-9013-4670-8cf7-479e7874f4f7",
   "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2F30%20Days%2F4.1.2.png?alt=media&token=0e3b2b75-7bde-41ad-a246-157e8669f9ac"]