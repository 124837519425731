export const routes = [
    {
        tab: 1,
    },
    {
        tab: 2,
    },
]

export const src1=[
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.18.1.png?alt=media&token=48c64dd7-6b48-42e4-9ea3-6c2df5dbc444",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.18.2.png?alt=media&token=a1a55c0e-79a5-481c-a8fd-d4f43fac8a64",
    "https://firebasestorage.googleapis.com/v0/b/manmastery-d7024.appspot.com/o/Subscription%2FPleasure%2F2.18.3.png?alt=media&token=996fb164-0b0c-4e13-92d4-db2981f2f6d4"
]

